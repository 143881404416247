/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.173
 * @Date 18-11-2024
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Chip } from "@mui/material";
import CustomTable from "../../Components/Tables/CoustomTable";
import {
  AddManufacturerDetails,
  DeleteManufacture,
  EditManufacture,
  GetManufacturerDetails,
  RawMaterialStockDetails,
} from "../Redux/Actions/bookWaterStockAction";
import { tabelConfig } from "../../Components/AppMeta/appTabelConfig";
import PrimaryButton from "../../Components/Buttons/primaryButton";
import CoustomAddItemsDialog from "../../Components/Custom/addItemsDialog";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { textFieldConfig } from "../../Components/AppMeta/appTextFieldConfig";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
export default function ManufactureDetails() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState("");
  const [openFlag, setOpenFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  //sample data
  const [manufactureData, setManufactureData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isEditButon, setIsEditButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [fieldValidity, setFieldValidity] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedToggelOption, setSelectedToggleOption] =
    useState("Add Manufacturer");

  //function to delete manufacturer
  const handelDelete = (props) => {
    const { row } = props;
    dispatch(
      EditManufacture({
        id: row?.id,
        is_active: false,
        manufacturer_name: row?.manufacturer_name,
        branch: row?.branch,
      })
    ).then((data) => {
      if (!data?.error) {
        setOpenDialog(false);
        fetchManufactureData();
      }
    });
  };

  //function to delete manufacturer
  const handelActivate = (props) => {
    const { row } = props;
    dispatch(
      EditManufacture({
        id: row?.id,
        is_active: true,
        manufacturer_name: row?.manufacturer_name,
        branch: row?.branch,
      })
    ).then((data) => {
      if (!data?.error) {
        setOpenDialog(false);
        fetchManufactureData();
      }
    });
  };

  //useeffect to get manufaturer details
  useEffect(() => {
    fetchManufactureData();
  }, []);

  //function to get manufacture data
  const fetchManufactureData = () => {
    dispatch(GetManufacturerDetails()).then((data) => {
      if (data?.data) {
        setManufactureData(data?.data?.data);
      } else {
        setManufactureData([]);
      }
    });
  };
  //const handelAdd manufacturer
  const handelAddManuFacture = () => {
    setIsEditButton(false);
    setOpenDialog(true);
  };

  //button componenet manufacturer
  const ManufacturerSubmitButton = (props) => {
    return (
      <Box>
        <PrimaryButton
          btnName="Submit"
          handleButtonClick={() => handelSubmitManufacture(props)}
          color={appColors.commonBtnColor}
        />
      </Box>
    );
  };

  //function to handel manufacture add details
  const handelSubmitManufacture = (props) => {
    let reqObject = {
      manufacturer_name: props?.manufacturer_name,
      branch: props?.branch,
      tax_details: { gstin: props?.gstin },
      company_profile: {
        owner_name: props?.owner_name,
        owner_phone: props?.owner_phone,
        owner_email: props?.owner_email,
        address: {
          contact_name: props?.contact_name,
          contact_phone: props?.phone,
          address_line1: props?.address_line1,
          address_line2: props?.address_line2,
          landmark: props?.landmark,
          pincode: props?.pincode,
          city: props?.city,
          state: props?.state,
          state_code: props?.state_code,
          address_line3: props?.address_line3,
          is_billing_address: false,
          country: props?.country,
          email: props?.email,
          district: props?.district,
        },
        other_details: {},
      },
    };

    if (isEditButon) {
      reqObject.id = props?.id;
      dispatch(EditManufacture(reqObject)).then((data) => {
        if (data?.data?.error === false) {
          setOpenDialog(false);
          fetchManufactureData();
          setFormValues({});
        }
      });
    } else {
      let validity = Object.values(fieldValidity)?.find((data) => {
        return data === false;
      });
      if (
        textFieldConfig.addManufacture?.length ===
          Object.keys(fieldValidity).length &&
        !isEditButon &&
        validity !== false
      ) {
        dispatch(AddManufacturerDetails(reqObject)).then((data) => {
          if (data?.data?.error === false) {
            setOpenDialog(false);
            fetchManufactureData();
            setFormValues({});
          }
        });
      } else {
        toast.error("Please Fill All Required Fields", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  console.log(fieldValidity);
  //button componenet rawmaterial
  const ManufacturerButton = (props) => {
    console.log(props);
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <EditIcon
          onClick={() => {
            handelEditButton(props);
          }}
          color="primary"
        />
        {props?.row?.is_active && (
          <PauseCircleIcon
            onClick={() => {
              handelDelete(props);
            }}
            color="error"
          />
        )}
        {!props?.row?.is_active && (
          <NotStartedIcon
            onClick={() => {
              handelActivate(props);
            }}
            color="success"
          />
        )}
      </Box>
    );
  };

  //function to close dialog
  const handelClose = () => {
    setOpenDialog(false);
  };

  //function to handel submit button for raw material
  const handelSubmitAddRawMaterial = (props) => {
    console.log(props);
  };

  //function to handel edit action
  const handelEditButton = (props) => {
    setIsEditButton(true);

    const { row } = props;
    console.log(row);
    setFormValues({
      manufacturer_name: row?.manufacturer_name,
      branch: row?.branch,
      owner_name: row?.company_profile?.owner_name,
      owner_email: row?.company_profile?.owner_email,
      address_line1: row?.company_profile?.address?.address_line1,
      address_line2: row?.company_profile?.address?.address_line2,
      address_line3: row?.company_profile?.address?.address_line3,
      state: row?.company_profile?.address?.state,
      state_code: row?.company_profile?.address?.state_code,
      pincode: row?.company_profile?.address?.pincode,
      city: row?.company_profile?.address?.city,
      district: row?.company_profile?.address?.district,
      country: row?.company_profile?.address?.country,
      gstin: row?.tax_details?.gstin,
      owner_phone: row?.company_profile?.owner_phone,
      id: row?.id,
      status: row?.is_active,
    });
    setOpenDialog(true);
  };

  //function to handel change options
  const tableRowClickFunction = (event, newAlignment) => {};

  // Function to get color and label based on status
  const getStatusDetails = (status) => {
    switch (status) {
      case true:
        return { label: "Active", color: "success" };
      case false:
        return { label: "Deactive", color: "warning" };
      default:
        return { label: "In Progress", color: "info" }; // Water Blue
    }
  };
  //order status function
  const OrderStatusFunction = (status) => {
    const { label, color } = getStatusDetails(status?.row?.is_active);
    return (
      <Box>
        <Chip label={label} color={color} variant="outlined" />
      </Box>
    );
  };
  return (
    <>
      {" "}
      {selectedToggelOption === "Add Manufacturer" && (
        <Box sx={{ width: "100%", overflow: "hidden", boxShadow: 2 }}>
          <CustomTable
            tableHead={tabelConfig.manufactureData.headerData}
            tableData={manufactureData}
            tableDataKey={[
              ...tabelConfig.manufactureData.tabelDataKey,
              {
                component: (props) => {
                  return OrderStatusFunction(props);
                },
              },
              {
                component: (props) => {
                  return ManufacturerButton(props);
                },
              },
            ]}
            addButton={true}
            addButtonName="Add Manufacturer"
            handleOnClickAddButton={handelAddManuFacture}
            pagination="100"
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            pageSize={pageSize}
            tableRowClickFunction={tableRowClickFunction}
          />
        </Box>
      )}
      {openDialog && (
        <CoustomAddItemsDialog
          openDialog={openDialog}
          handelClose={handelClose}
          dialogTittle={isEditButon ? "Edit Manufacturer" : "Add Manufacturer"}
          fieldsConfig={textFieldConfig.addManufacture}
          submitButton={ManufacturerSubmitButton}
          formValues={formValues}
          setFormValues={setFormValues}
          fieldValidity={fieldValidity}
          setFieldValidity={setFieldValidity}
        />
      )}
    </>
  );
}
