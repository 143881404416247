import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

function ManuFactureFilter({
  openFilterDialog,
  handelClose,
  handleFilter,
  selectedFilters,
  setSelectedFilters,
}) {
  const handelCloseDialog = () => {
    handelClose();
  };
  const handleFilterDialog = () => {
    handleFilter();
  };
  const statusOptions = {
    "100,200,300": "PENDING",
    100: "IN_PROGRESS",
    200: "PO_APPROVED",
    202: "PO_REJECTED",
    300: "PR_APPROVED",
    400: "CANCELLED",
    500: "COMPLETED",
  };
  const handleClearAll = () => {
    setSelectedFilters({
      order_status: "",
      created_at: "DESC",
      order_id: "",
    });
  };
  return (
    <Dialog open={openFilterDialog}>
      <DialogTitle align="center">Filter</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <TextField
            label="Order Id"
            variant="outlined"
            size="small"
            sx={{ width: 200 }}
            value={selectedFilters?.order_id}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                order_id: event.target.value,
              }))
            }
          />
          {/* <TextField
            type="number"
            size="small"
            label="Batch Id"
            sx={{ width: 200 }}
            value={selectedFilters?.id}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                id: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          /> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <FormControl sx={{ width: 200 }} size="small">
            <InputLabel>Select Status</InputLabel>
            <Select
              value={selectedFilters?.order_status}
              label="Select Status"
              onChange={(event) =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  order_status: event.target.value,
                }))
              }
            >
              {Object.entries(statusOptions).map(([value, label]) => (
                <MenuItem key={value} value={Number(value)}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <TextField
            type="text"
            size="small"
            label="Batch Name"
            sx={{ width: 200 }}
            value={selectedFilters?.batchName}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                batchName: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          /> */}
          <FormControl sx={{ width: 200 }} size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedFilters?.created_at}
              label="Select Month"
              onChange={(event) =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  created_at: event.target.value,
                }))
              }
            >
              <MenuItem value="DESC">Recent First</MenuItem>
              <MenuItem value="ASC">Old First</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        ></Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            gap: "10px",
            m: "10px",
          }}
        >
          <TextField
            type="date"
            size="small"
            label="From Date"
            value={selectedFilters?.from}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                from: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            type="date"
            size="small"
            label="To Date"
            value={selectedFilters?.to}
            onChange={(event) =>
              setSelectedFilters((prev) => ({
                ...prev,
                to: event.target.value,
              }))
            }
            InputLabelProps={{ shrink: true }}
          />
        </Box> */}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClearAll}
          size="small"
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handelCloseDialog}
          size="small"
        >
          close
        </Button>
        <Button variant="contained" onClick={handleFilterDialog} size="small">
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ManuFactureFilter;
