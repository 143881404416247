import { useState, useEffect } from "react";
import ManufactureOrderTabel from "../../Components/Tables/maufactureOrderTable";
import { useDispatch } from "react-redux";
import {
  GetManufacturerDetails,
  RawMaterialStockDetails,
  ValidateManufactureOrder,
} from "../Redux/Actions/bookWaterStockAction";
import {
  Box,
  Checkbox,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomSelectField from "../../Components/TextFiled/coustomSearchTextField";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import ValidateManufactureOrderView from "./validateOrder";
import BasicSelectField from "../../Components/Custom/selectField";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ManufactureOrderDetails from "./manufactureOrders";
import { toast } from "react-toastify";
import { MFT_ORDER_DECLERATIONS } from "../../Components/AppMeta/appMetaConfig";

export default function ManufatureOrders() {
  const dispatch = useDispatch();
  const [tabelData, setTabelData] = useState([]);
  const [addresses, setAddresses] = useState({
    billing_address: null,
    delivery_address: null,
    is_same_state: false,
    user_prefered_is_same_state: true,
  });

  const [manufacturerDetails, setManufacturerDetails] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [declaration, setDeclaration] = useState(MFT_ORDER_DECLERATIONS);
  const [groupProducts, setGroupProducts] = useState([]);
  const [addToCartButtonFlag, setAddToCartButtonFlag] = useState(false);
  const [cartPageFlag, setCartPageFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [validateData, setValidateData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState("Final Products");
  const [orderId, setOrderId] = useState();
  const [selectedToggelOption, setSelectedToggelOption] =
    useState("orderDetails");
  //useEffect to fetch data for raw material
  useEffect(() => {
    setSelectedProducts([]);
    switch (selectedOptions) {
      case "Raw Material":
        fetchRawMaterialData();
        break;
      case "Finished Goods":
        fetchFineshedProductsData(200);
        break;
      case "Final Products":
        fetchFineshedProductsData(100);
        break;
      default:
        setTabelData([]);
    }
    fetchManufacturerDetails({ is_active: true });
  }, [selectedOptions]);
  useEffect(() => {
    console.log(addresses);
    console.log(validateData);

    // TODO:  Do not Change
    // ! It is a circular dependancy. Care to validate the condition before run.
    if (
      addresses.billing_address?.supplier_profile?.state_code !=
      validateData?.mft_details?.company_profile?.address?.state_code
    ) {
      // if (!addresses?.is_same_state) {
      setAddresses((prev) => ({ ...prev, is_same_state: true }));
      // }
    } else {
      // if (addresses?.is_same_state) {
      // if (addresses?.is_same_state) {
      setAddresses((prev) => ({ ...prev, is_same_state: false }));
      // }
    }
  }, [addresses, validateData]);
  //function to call raw material api
  const fetchRawMaterialData = () => {
    dispatch(RawMaterialStockDetails()).then((details) => {
      const { data } = details;
      if (!data?.error) {
        let activeProduct = data?.data.filter((data) => data?.status === true);
        setTabelData(activeProduct);
      } else {
        setTabelData([]);
      }
    });
  };
  //function to call raw material api
  const fetchFineshedProductsData = (type) => {
    dispatch(
      GetAvailableProducts({ product_type: type === 200 ? type : "" })
    ).then((details) => {
      const { data } = details;
      if (!data?.error) {
        const productDetails = data
          ?.map((product) => product?.product_details)
          .filter((details) =>
            selectedOptions === "Final Products"
              ? details?.product_type === 100
              : details?.product_type === 200
          );
        setTabelData(productDetails);
      } else {
        setTabelData([]);
      }
    });
  };

  //fetch function for getting manufacturer details
  const fetchManufacturerDetails = (params) => {
    dispatch(GetManufacturerDetails(params)).then((details) => {
      const { data } = details;
      if (!data?.error) {
        setManufacturerDetails(data?.data);
      } else {
        setManufacturerDetails([]);
      }
    });
  };

  //function to handel change check box
  const handleCheckboxChange = (value, row) => {
    const productId = row?.id;
    setSelectedProducts((prev) => {
      const existingProduct = prev.find((product) => product.id === productId);

      if (existingProduct) {
        // Deselect product if deselected
        return prev.filter((product) => product.id !== productId);
      } else {
        return [
          ...prev,
          {
            mft_id: manufacturerDetails[0]?.id,
            id: productId,
            products: [
              {
                quantity: 0,
                price: 0,
                material: row?.material,
                capacity: row?.capacity,
                product_type: row?.product_type,
              },
            ],
          },
        ];
      }
    });
  };
  // Function to get table columns based on selected options
  const getTableColumns = () => {
    const commonColumns = [
      {
        header: "Required Qty",
        field: "required_qty",
        render: (value, row, index) => (
          <TextField
            variant="outlined"
            size="small"
            type="text"
            value={
              selectedProducts?.find((product) => product.id === row.id)
                ?.products[0]?.quantity || ""
            }
            onChange={(event) => handelQuantityChange(event, row)}
          />
        ),
      },
      {
        header: "Price Per Qty",
        field: "price_per_qty",
        render: (value, row) => (
          <TextField
            variant="outlined"
            type="text"
            size="small"
            value={
              selectedProducts?.find((product) => product.id === row.id)
                ?.products[0]?.price || ""
            }
            onChange={(event) => handelPriceChange(event, row)}
          />
        ),
      },
      {
        header: "Manufacturer",
        field: "manufacturer",
        render: (value, row) => {
          const selectedManufacturer = selectedProducts.find(
            (product) => product?.id === row?.id
          )?.mft_id;

          return (
            <CustomSelectField
              details={manufacturerDetails}
              label="Manufacturer"
              keyName="id"
              valueName="manufacturer_name"
              secondaryName="branch"
              value={
                manufacturerDetails.find((m) => m?.id === selectedManufacturer)
                  ?.id || null
              }
              onChange={(data) => handelChangeManufacturerSelect(data, row)}
              renderAdditionalDetails={(option) => `Branch: ${option.branch}`}
            />
          );
        },
      },
    ];
    if (
      selectedOptions === "Final Products" ||
      selectedOptions === "Finished Goods"
    ) {
      return [
        {
          header: "Select",
          field: "checkbox",
          render: (value, row) => (
            <Checkbox
              checked={selectedProducts?.some((value) => row.id === value.id)}
              onChange={() => handleCheckboxChange(value, row)}
            />
          ),
        },
        {
          header: "S.No",
          field: "index",
          render: (_, __, index) => index + 1, // Render index
        },
        {
          header: "Name",
          field: "name",
        },
        {
          header: "Capacity",
          field: "capacity",
        },
        {
          header: "Material",
          field: "material",
        },
        ...commonColumns,
      ];
    }
    if (selectedOptions === "Raw Material") {
      return [
        {
          header: "Select",
          field: "checkbox",
          render: (value, row) => (
            <Checkbox
              checked={selectedProducts?.some((value) => row.id === value.id)}
              onChange={() => handleCheckboxChange(value, row)}
            />
          ),
        },
        {
          header: "S.No",
          field: "index",
          render: (_, __, index) => index + 1, // Render index
        },
        {
          header: "Product Name",
          field: "name",
        },
        {
          header: "Material",
          field: "material",
        },
        ...commonColumns,
      ];
    }

    return commonColumns;
  };
  //function to manufacture details on change
  const handelChangeManufacturerSelect = (data, row) => {
    const productId = row?.id;
    setSelectedProducts((prev) => {
      return prev.map((product) => {
        if (product.id === productId) {
          return { ...product, mft_id: data?.id };
        }
        return product;
      });
    });
  };

  //function to handel qty change
  const handelQuantityChange = (event, row) => {
    const newQuantity = event.target.value;
    console.log(newQuantity === "");
    const productId = row?.id;
    if (newQuantity >= 0 || newQuantity === "") {
      setSelectedProducts((prev) => {
        return prev.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              products: product.products.map((item) => ({
                ...item,
                quantity: newQuantity, // Update the quantity
              })),
            };
          }
          return product;
        });
      });
    }
  };

  //function to handel price change
  const handelPriceChange = (event, row) => {
    const productId = row?.id;
    let newPrice = event.target.value;
    if (newPrice >= 0 || newPrice === "") {
      setSelectedProducts((prev) => {
        return prev.map((product) => {
          if (product.id === productId) {
            return {
              ...product,
              products: product.products.map((item) => ({
                ...item,
                price: newPrice, // Update the quantity
              })),
            };
          }
          return product;
        });
      });
    }
  };

  //function to group product and order details
  function groupProductsByManufacturer() {
    for (const item of selectedProducts) {
      if (item?.mft_id === undefined) {
        toast.error("Please select a valid manufacturer", {
          position: "bottom-right",
          autoClose: 3000,
        });
        return;
      }
    }
    const groupedData = selectedProducts.reduce((acc, item) => {
      const existingManufacturer = acc.find(
        (group) => group.mft_id === item.mft_id
      );

      if (existingManufacturer) {
        existingManufacturer.products.push(...item.products);
      } else {
        acc.push({
          mft_id: item.mft_id,
          products: [...item.products],
        });
      }
      return acc;
    }, []);
    setGroupProducts(groupedData);
    setAddToCartButtonFlag(true);
  }
  console.log(groupProducts);
  //useEffect to call add to cart api
  useEffect(() => {
    if (addToCartButtonFlag) {
      setAddToCartButtonFlag(false);
      let reqObject = {
        order_type: 100,
        orders: groupProducts,
      };
      dispatch(ValidateManufactureOrder(reqObject)).then((details) => {
        const { data } = details;
        console.log(data?.data?.orders);
        if (!data?.error) {
          setValidateData(data?.data?.orders);
          setCartPageFlag(true);
        }
      });
    }
  }, [addToCartButtonFlag]);

  //function to control pagenumber change
  const handleChangePage = (event, pageNumber) => {
    setPageNumber(pageNumber);
    console.log(pageNumber);
  };

  //function to control page size
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  //function for selection option
  const SelectOptions = () => {
    return (
      <Box sx={{ width: "10rem" }}>
      <BasicSelectField
        options={["Final Products", "Finished Goods", "Raw Material"]}
        selectedoptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        helperText="Select Order Type"
      />
      </Box>
    );
  };

  //function to select toggle option
  const handleChangeToggle = (event, newValue, order_id) => {
    setSelectedToggelOption(newValue);
    if (order_id) {
      setOrderId(order_id);
    }
  };
  console.log("manufacturerDetails");
  console.log(manufacturerDetails);
  console.log(selectedProducts);
  console.log(groupProducts);
  console.log(tabelData);
  console.log(selectedOptions);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={selectedToggelOption}
            onChange={handleChangeToggle}
            aria-label="Platform"
            exclusive
            size="small"
          >
            <ToggleButton value="placeOrder">Place Orders</ToggleButton>
            <ToggleButton value="orderDetails">Order Details</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {/* Used only for stock and product adding*/}
        {selectedToggelOption === "placeOrder" && (
          <>
            <Box>
              {!cartPageFlag && (
                <>
                  <ManufactureOrderTabel
                    columnRowData={getTableColumns()}
                    tabelData={tabelData}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    pageNumber={pageNumber}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    SelectOptions={SelectOptions}
                  />
                  <Fab
                    color="primary"
                    variant="extended"
                    sx={{ position: "absolute", bottom: 105, right: 0 }}
                    size="small"
                    onClick={groupProductsByManufacturer}
                    disabled={!selectedProducts?.length}
                  >
                    <AddIcon />
                    Order
                  </Fab>
                </>
              )}

              {cartPageFlag && (
                <ValidateManufactureOrderView
                  declaration={declaration}
                  setCartPageFlag={setCartPageFlag}
                  validateData={validateData}
                  addresses={addresses}
                  setDeclaration={setDeclaration}
                  setAddresses={setAddresses}
                  setValidateData={setValidateData}
                  setSelectedToggelOption={(value, order_id) =>
                    handleChangeToggle({}, value, order_id)
                  }
                  setSelectedProducts={setSelectedProducts}
                />
              )}
            </Box>
          </>
        )}

        {selectedToggelOption === "orderDetails" && (
          <>
            <ManufactureOrderDetails orderId={orderId} />
          </>
        )}
      </Box>
    </>
  );
}
