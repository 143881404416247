/**
 * @author Gowtham Prasath
 * @description Book Now
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 21-06-2023
 */

import { Box, TextField, Button, InputAdornment, Chip } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { GetRolesList } from "../Redux/Actions/addUserAdminAction";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { GetUserBasedName } from "../Redux/Actions/userDetailsAdminAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FcSearch } from "react-icons/fc";
import CustomTable from "../../Components/Tables/CoustomTable";
import { tabelConfig } from "../../Components/AppMeta/appTabelConfig";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";

function ActiveUsersTable() {
  const history = useHistory();
  const superAdminUpdates = useSelector((state) => state.superAdminUpdates);
  const updateUserDetails = useSelector((state) => state.userDetailsUpdate);
  const [userDetails, setUserDetails] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [value, setValue] = React.useState("1");
  const [selectedRoleName, setSelectedRoleName] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState("");
  const [searchName, setSearchName] = React.useState("");
  const [searchEmail, setSearcEmail] = React.useState("bookwater.com");
  const [pageNumber, setPageNumber] = React.useState(0);
  const [roles, setRoles] = React.useState([]);

  const dispatch = useDispatch();
  CustomConsole(updateUserDetails);


  //function to user details
  React.useEffect(() => {
    CustomConsole("------User Search details-----");
    CustomConsole(updateUserDetails);
    if (updateUserDetails.userNames) {
      setUserDetails(updateUserDetails.userNames);
    } else {
      setUserDetails([]);
    }
  }, [updateUserDetails.userNames]);

  // React.useEffect(() => {}, [pageSize]);
  React.useEffect(() => {
    CustomConsole(superAdminUpdates);
    if (superAdminUpdates.adminRoleList) {
      setRoles(superAdminUpdates.adminRoleList);
    }
  }, [superAdminUpdates]);

  //function to handel role changes
  const handleChangeRoles = (event) => {
    setPageNumber(0);
    setSearcEmail("");
    setSearchName("");
    CustomConsole(event.target.value);
    setSelectedRole(event.target.value);
    dispatch(
      GetUserBasedName({
        role_id: event.target.value,
        page_number: 0,
        page_size: pageSize,
      })
    );
    roles.map((role) => {
      if (role.role_id === event.target.value) {
        CustomConsole(role.role_name);
        setSelectedRoleName(role.role_name);
      }
    });
  };

  //function to hadel search name
  const handleSearchName = () => {
    setPageNumber(0);
    setSelectedRole("");
    setSearcEmail("");
    setSelectedRoleName("");
    dispatch(
      GetUserBasedName({
        name: searchName,
        page_number: 0,
        page_size: pageSize,
      })
    );
  };

  //function to handel search email
  const handleSearchEmail = () => {
    setPageNumber(0);
    setSearchName("");
    setSelectedRole("");
    setSelectedRoleName("");
    dispatch(
      GetUserBasedName({
        email: searchEmail,
        page_number: 0,
        page_size: pageSize,
      })
    );
  };

  //function to handel reirect to users page
  const handleRedirecttoUsers = (phone) => {
    CustomConsole("------------handleRedirecttoUsers--------------");
    const url = `/dashboard/UserDetailsMain?phoneNumber=${phone}`;
    history.push(url);
  };

  //function to  handel search name
  React.useEffect(() => {
    if (searchName) {
      dispatch(
        GetUserBasedName({
          name: searchName,
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    } else if (searchEmail) {
      dispatch(
        GetUserBasedName({
          email: searchEmail,
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    } else if (selectedRole) {
      dispatch(
        GetUserBasedName({
          role_id: selectedRole,
          page_number: pageNumber,
          page_size: pageSize,
        })
      );
    }
  }, [pageNumber, pageSize]);

  //function to get user role details
  const userRoleFunction = (status) => {
    return (
      <Box>
        <Chip
          label={
            status?.row?.role_id === ADMIN_ROLES.BW_ADMIN
              ? "Admin"
              : status?.row?.role_id === ADMIN_ROLES.BW_SUPERADMIN
              ? "Super Admin"
              : status?.row?.role_id === ADMIN_ROLES.BW_CONSUMER
              ? "Consumer"
              : status?.row?.role_id === ADMIN_ROLES.BW_WFSADMIN
              ? "WaterFilling Station"
              : status?.row?.role_id === ADMIN_ROLES.BW_DISTRIBUTER
              ? "Distributor"
              : status?.row?.role_id === ADMIN_ROLES.BW_CS
              ? "Customer Support"
              : status?.row?.role_id === ADMIN_ROLES.BW_CSHEAD
              ? "Customer Support Head"
              : status?.row?.role_id === ADMIN_ROLES.BW_FINANCE
              ? "Finance"
              : status?.row?.role_id === ADMIN_ROLES.BW_FINANCEHEAD
              ? "Finance Head"
              : status?.row?.role_id === ADMIN_ROLES.BW_OPS
              ? "Operations"
              : status?.row?.role_id === ADMIN_ROLES.BW_OPSHEAD
              ? "Operation Head"
              : status?.row?.role_id === ADMIN_ROLES.BW_QA
              ? "QA"
              : status?.row?.role_id === ADMIN_ROLES.BW_QAHEAD
              ? "QA HEAD"
              : status?.row?.role_id === ADMIN_ROLES.BW_DELIVERYAGENT
              ? "Delivery Agent"
              : status?.row?.role_id === ADMIN_ROLES.BW_SUPPLIERMANAGER
              ? "Warehouse Manager"
              : status?.row?.role_id === ADMIN_ROLES.BW_SUPPLIER_LOADERS
              ? "Loader"
              : ""
          }
          color="success"
          variant="outlined"
        />
      </Box>
    );
  };

  //function to show edit icon
  const editProductsIcon = (props) => {
    return (
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleRedirecttoUsers(props?.row?.phone)}
        >
          view
        </Button>{" "}
      </Box>
    );
  };

  //dummy function
  const tableRowClickFunction = () => {};
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          padding: "10px",
          justifyContent: "space-evenly",
        }}
      >
        <TextField
          label="Search Name"
          size="small"
          value={searchName}
          InputProps={{
            style: {
              borderRadius: "30px",
              // border: "2px solid blue",
            },
            endAdornment: (
              <InputAdornment position="end">
                <FcSearch size="30px" onClick={handleSearchName} />
              </InputAdornment>
            ),
          }}
          onChange={(event) => setSearchName(event.target.value)}
        />
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="demo-select-small">Select role to add</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={selectedRole}
            size="small"
            label="Select role to add"
            onChange={handleChangeRoles}
          >
            {roles.map((role) => {
              return <MenuItem value={role.role_id}>{role.role_name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <TextField
          label="Search Email"
          size="small"
          value={searchEmail}
          InputProps={{
            style: {
              borderRadius: "30px",
              // border: "2px solid gray",
            },
            endAdornment: (
              <InputAdornment position="end">
                <FcSearch size="30px" onClick={handleSearchEmail} />
              </InputAdornment>
            ),
          }}
          onChange={(event) => setSearcEmail(event.target.value)}
        />
      </Box>
      <Box>
        <CustomTable
          tableHead={tabelConfig.activeUsers.headerData}
          tableData={userDetails}
          tableDataKey={[
            ...tabelConfig.activeUsers.tabelDataKey,
            {
              component: (props) => {
                return userRoleFunction(props);
              },
            },
            {
              component: (props) => {
                return editProductsIcon(props);
              },
            },
          ]}
          tableRowClickFunction={tableRowClickFunction}
          pagination="200"
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          filterButton={false}
        />
      </Box>
    </>
  );
}

export default ActiveUsersTable;
