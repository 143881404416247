/**
 * @copycenter Copycenter (c) 2024 Bookwater
 * @author Gowtham Prasath
 * @date 08-01-2025
 * @description Component to get the list of PDE Order details
 */
//Importing the libraries
import React, { useState } from "react";
import CustomConsole from "../../CustomConsole";
import { Box, IconButton, TextField } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import {
  GetDeliverySlotsList,
  GetNewSearchOrderDetails,
  GetPdwOrderDetails,
  GetPdwOrderSummaryDetails,
} from "../Redux/Actions/ordersAdminAction";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Filter from "../../Images/Filter.svg";
import { SearchUserAdmin } from "../Redux/Actions/addUserAdminAction";
import { GetAvailableProducts } from "../Redux/Actions/SKUProductDetailsAction";
import { toast } from "react-toastify";
import OrdersPageTabelView from "../../Components/Tables/OrdersTabel";
import { styles } from "./ordersStyle";
import OrdersFilterData from "../../Components/Cards/ordersFilters";
import { OrderSummary } from "../../Components/Cards/orderSummaryCards";
import CoustomPagiation from "../../Components/Custom/pagination";
const TZ = "Asia/Kolkata";
//Main Component
function RegularOrders({ setIsEditOrderDetailsDialog }) {
  const allOrderData = useSelector((state) => state.orderDetailsAdmin);
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = React.useState(true);
  const orderDetailsList = useSelector((state) => state.orderDetailsAdmin);
  const seacrchedUserStore = useSelector((state) => state.addUserAdminDetails);
  const availableProducts = useSelector(
    (state) => state.newProductContainerDetails
  );
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [orderSummary, setOrderSummary] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [user, setUser] = React.useState(" ");
  const [pincode, setPincode] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [product, setProduct] = React.useState(" ");
  const [sortBy, setSortBy] = React.useState("Order ID (Z-A)");
  const [orderType, setOrderType] = React.useState("None");
  const [filterCity, setFilterhouseCity] = useState("");
  const intialParamsState = {
    pincode: "" || undefined,
    locality: "" || undefined,
    district: "" || undefined,
    country: "INDIA",
    state: "" || undefined,
    page_size: 25,
    page_number: 1,
  };
  const [queryParams, setQueryParams] = React.useState(intialParamsState);
  const [selectedZoneName, setSelectedZoneName] = React.useState("");
  const [selectedState, setSelectedState] = useState("");
  const [clearFilterFlag, setClearFilterFlag] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [orderStatus, setOrderStatus] = React.useState(0);
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [consumerId, setConsumerId] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState(0);
  const [driverId, setDriverId] = React.useState(0);
  const [previouseDates, setPreviousDates] = React.useState({
    from: "",
    to: "",
  });
  const [isUserSearchValid, setIsUserSearchValid] = React.useState(false);
  const [products, setProducts] = React.useState([
    { capacity: "None", material: " ", name: " " },
  ]);
  const orderTypes = ["None", "INSTA", "REGULAR", "POSTPAID", "SUBSCRIBED"];
  const orderStatusDetails = [
    { key: 0, value: "None" },
    { key: 100, value: "New requests" },
    { key: 200, value: "Ongoing" },
    { key: 500, value: "Delivered" },
    { key: 400, value: "Cancelled" },
    { key: 300, value: "Rejected" },
  ];
  const sorKeys = [
    { key: "order_id", value: "Order ID (A-Z)", order: "ASC" },
    { key: "order_id", value: "Order ID (Z-A)", order: "DESC" },
    { key: "order_status_name", value: "Order Status (A-Z)", order: "ASC" },
    { key: "order_status_name", value: "Order Status (Z-A)", order: "DESC" },
    { key: "order_type_name", value: "Order Type (A-Z)", order: "ASC" },
    { key: "order_type_name", value: "Order Type (Z-A)", order: "DESC" },
    { key: "delivery_slot", value: "Delivery Slot (A-Z)", order: "ASC" },
    { key: "delivery_slot", value: "Delivery Slot (Z-A)", order: "DESC" },
    { key: "pincode", value: "Pincode (A-Z)", order: "ASC" },
    { key: "pincode", value: "Pincode (Z-A)", order: "DESC" },
    { key: "booking_date", value: "Booking Date  (A-Z)", order: "ASC" },
    { key: "booking_date", value: "Booking Date  (Z-A)", order: "DESC" },
    { key: "booking_time", value: "Booking Time  (A-Z)", order: "ASC" },
    { key: "booking_time", value: "Booking Time  (Z-A)", order: "DESC" },
    { key: "delivery_date", value: "Delivery Date  (A-Z)", order: "ASC" },
    { key: "delivery_date", value: "Delivery Date  (Z-A)", order: "DESC" },
    { key: "delivery_time", value: "Delivery Time  (A-Z)", order: "ASC" },
    { key: "delivery_time", value: "Delivery Time  (Z-A)", order: "DESC" },
    { key: "consumer_name", value: "Consumer Name (A-Z)", order: "ASC" },
    { key: "consumer_name", value: "Consumer Name (Z-A)", order: "DESC" },
    { key: "payment_status_name", value: "Payment Status (A-Z)", order: "ASC" },
    {
      key: "payment_status_name",
      value: "Payment Status (Z-A)",
      order: "DESC",
    },
    { key: "payment_type_name", value: "Payment Type (A-Z)", order: "ASC" },
    { key: "payment_type_name", value: "Payment Type (Z-A)", order: "DESC" },
    { key: "payment_method", value: "Payment Method (A-Z)", order: "ASC" },
    { key: "payment_method", value: "Payment Method (Z-A)", order: "ASC" },
  ];
  const pageSizeList = [25, 50, 100, 200, 500, 1000, 2500, 5000];
  const tableHeader = [
    "S.No",
    "Order ID",
    "OTP",
    "Pincode",
    "Service Area",
    "20L Can Qty.",
    "Delivery Slot",
    "Delivery Contact Details",
    "Vehicle ID",
    "Driver Details",
    "Order Type",
    "Order Status",
    "Consumer Details",
    "Payment Method",
    "Payment Status",
    "Order Price(With Discount)",
    "Discount",
    "Discount Type",
    "Supplier",
    "Booking Date & Time",
    "Delivery Date & Time",
    "Payment Details",
    "Last Updated at",
    "Last Updated by",
    "Cancel Reason",
  ];
  const [fromDate, setFromDate] = React.useState(
    moment().tz(TZ).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(
    moment().tz(TZ).format("YYYY-MM-DD")
  );
  //On-Mount Hit the Products And Get the Available Products
  React.useState(() => {
    if (isMounted) {
      CustomConsole("isMounted: ", isMounted);
      dispatch(GetAvailableProducts());
      setIsMounted(false);
    }
  }, [isMounted]);
  //Available Products Rendering & Updating the Products Variable
  React.useEffect(() => {
    const prod = [{ capacity: "None", material: " ", name: " " }];
    if (
      availableProducts.getAvailableProducts &&
      availableProducts.getAvailableProducts.length
    ) {
      for (const data of availableProducts.getAvailableProducts) {
        prod.push({
          capacity: data.product_details.capacity,
          material: data.product_details.material,
          name: data.product_details.name,
        });
      }
    }
    setProducts(prod);
  }, [availableProducts.getAvailableProducts]);

  //storing user number from users page
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phoneNumberFromOrders = searchParams.get("phoneNumber");
  //storing Order Id from URL(Redirecting from Transaction page)
  const order_id = searchParams.get("order_id");
  //setting the Order Id and hitting the API for search user with the Order Id from URL(Redirecting from Transaction page)
  React.useEffect(() => {
    CustomConsole(order_id);
    if (order_id) {
      CustomConsole("------------order_id---------");
      // handleSearchOrderId();
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 1,
        page_number: 1,
        order_ids: [order_id],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      setTimeout(() => {
        dispatch(GetDeliverySlotsList());
      }, 1000);
      setIsEditOrderDetailsDialog(true);
    } else {
      setIsEditOrderDetailsDialog(false);
    }
  }, [order_id]);

  React.useEffect(() => {
    CustomConsole("user: VINBAY " + user);
    if (user === undefined || user === null || (!user.length && user === "")) {
      CustomConsole("SUCCESS");
      setConsumerId(0);
      setSupplierId(0);
      setDriverId(0);
    }
  }, [user]);
  // Setting the phone number and hitting the API for search user with the phone number from URL(Redirecting from users page)
  React.useEffect(() => {
    setUser(phoneNumberFromOrders);
    if (phoneNumberFromOrders !== null) {
      dispatch(SearchUserAdmin(phoneNumberFromOrders));
    }
  }, [phoneNumberFromOrders]);
  //Pdw Order Details rendering for Showing the Data to user (Response from API)
  React.useEffect(() => {
    console.log(orderDetailsList.pdwOrderSummary);
    if (
      orderDetailsList.pdwOrderDetails &&
      orderDetailsList.pdwOrderDetails.order_summary &&
      Object.keys(orderDetailsList.pdwOrderDetails.order_summary).length
    ) {
      setOrderSummary([
        {
          orderType: "Insta",
          data: [
            {
              header: "Total",
              value: orderDetailsList.pdwOrderDetails.order_summary.insta.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta.ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta.delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.insta.cancelled,
            },
          ],
        },
        {
          orderType: "Regular",
          data: [
            {
              header: "Total",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular.ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular
                  .delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.regular
                  .cancelled,
            },
          ],
        },
        {
          orderType: "Postpaid",
          data: [
            {
              header: "Total",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid.ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid
                  .delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.postpaid
                  .cancelled,
            },
          ],
        },
        {
          orderType: "Subscription",
          data: [
            {
              header: "Total",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed.total,
            },
            {
              header: "New Requests",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .new_requests,
            },
            {
              header: "Ongoing",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .ongoing,
            },
            {
              header: "Delivered",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .delivered,
            },
            {
              header: "Cancelled",
              value:
                orderDetailsList.pdwOrderDetails.order_summary.subscribed
                  .cancelled,
            },
          ],
        },
      ]);
    } else {
      setOrderSummary([]);
    }
    if (
      orderDetailsList.pdwOrderDetails &&
      orderDetailsList.pdwOrderDetails.orders &&
      orderDetailsList.pdwOrderDetails.orders.length
    ) {
      setOrderList(orderDetailsList.pdwOrderDetails.orders);
    } else {
      setOrderList([]);
    }
    // if (consumerId) {
    //   setConsumerId(0);
    // }
    // if (supplierId) {
    //   setSupplierId(0);
    // }
    // if (driverId) {
    //   setDriverId(0);
    // }
    if (isUserSearchValid) {
      setIsUserSearchValid(false);
    }
  }, [orderDetailsList.pdwOrderDetails]);
  //User Details (Searched User)
  React.useEffect(() => {
    if (seacrchedUserStore.users && seacrchedUserStore.users.length) {
      if (seacrchedUserStore.users[0].consumer_id) {
        CustomConsole("---------users----------");
        setConsumerId(seacrchedUserStore.users[0].consumer_id);
        setDriverId(0);
        setSupplierId(0);
      } else if (seacrchedUserStore.users[0].delivery_agent_id) {
        setConsumerId(0);
        setDriverId(seacrchedUserStore.users[0].delivery_agent_id);
        setSupplierId(0);
      } else if (seacrchedUserStore.users[0].supplier_id) {
        setConsumerId(0);
        setDriverId(0);
        setSupplierId(seacrchedUserStore.users[0].supplier_id);
      }
    } else {
      setConsumerId(0);
      setDriverId(0);
      setSupplierId(0);
    }
  }, [seacrchedUserStore.users]);
  //Render & Hit the Order Details API on Seaching the User by phone or Email
  React.useEffect(() => {
    if (supplierId || consumerId || driverId) {
      OrderDetailsAndSummaryApi();
    }
  }, [supplierId, consumerId, driverId]);
  //User Effect to Render the Variables on Change
  React.useEffect(() => {}, [orderSummary, orderList, user, orderId]);
  //Get the Order Details on Change in PageNumber or Pagesize or sortBy
  React.useEffect(() => {
    OrderDetailsAndSummaryApi();
  }, [sortBy, pageNumber, pageSize]);


  //function to hit summary api
  const OrderDetailsAndSummaryApi = () => {
    let sortByDetails = {};
    const sortData = sorKeys.filter((data) => data.value === sortBy);
    if (sortData && sortData.length) {
      sortByDetails.key = sortData[0].key;
      sortByDetails.value = sortData[0].order;
    }
    let finalSelectedProduct = [];
    CustomConsole(product);
    if (product && product.length) {
      finalSelectedProduct = products.filter(
        (item) =>
          item.name === product &&
          item.name.trim() !== "" &&
          item.capacity !== "None"
      );
    }
    CustomConsole(finalSelectedProduct);
    dispatch(
      GetPdwOrderDetails({
        is_order_summary_required: true,
        delivery_from_date: fromDate,
        delivery_to_date: toDate,
        page_size: pageSize,
        page_number: pageNumber + 1,
        sort_by: Object.keys(sortByDetails).length
          ? [sortByDetails]
          : undefined,
        order_status: orderStatus ? orderStatus : undefined,
        country: selectedCountry ? selectedCountry : undefined,
        city: filterCity ? filterCity : undefined,
        state: selectedState ? selectedState : undefined,
        pincode: pincode ? pincode : undefined,
        order_type:
          orderType.trim().length && orderType !== "None"
            ? orderType.trim()
            : undefined,
        // order_ids: orderId.length ? [orderId] : undefined,
        consumer_id: consumerId ? consumerId : undefined,
        supplier_id: supplierId ? supplierId : undefined,
        delivery_agent_id: driverId ? driverId : undefined,
        products: finalSelectedProduct.length
          ? finalSelectedProduct
          : undefined,
        zone_name: selectedZoneName || undefined,
      })
    );
    if (
      previouseDates.from !== fromDate ||
      previouseDates.to !== toDate ||
      selectedZoneName ||
      selectedState ||
      selectedCountry ||
      consumerId ||
      pincode ||
      clearFilterFlag
    ) {
      setPreviousDates({
        from: fromDate,
        to: toDate,
      });
      dispatch(
        GetPdwOrderSummaryDetails({
          delivery_from_date: fromDate,
          delivery_to_date: toDate,
          zone_name: selectedZoneName || undefined,
          state: selectedState ? selectedState : undefined,
          country: selectedCountry ? selectedCountry : undefined,
          consumer_id: consumerId ? consumerId : undefined,
          pincode: pincode ? pincode : undefined,
        })
      );
    }
  };
  //Search User Order based on User Name or Phone or Email
  const handleSeacrhUserOrders = () => {
    CustomConsole("-------------handleSeacrhUserOrders------------------");
    const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const regPhone = /^[0-9][0-9]{9}$/g;
    //Admin Entered an Email or Phone
    CustomConsole(user);
    if (
      user !== undefined &&
      user !== null &&
      (regPhone.test(user.trim()) || regEmail.test(user.trim()))
    ) {
      dispatch(SearchUserAdmin(user.trim()));
    } else {
      setConsumerId(0);
      setSupplierId(0);
      setIsUserSearchValid(true);
      CustomConsole("Please enter valid phone/email/name of the user!");
    }
  };
  //Search the Order details by order Id
  const handleSearchOrderId = (props) => {
    allOrderData.newallOrderDetails = [];
    //@ToDo : Vinay -> Hit the Single Order API & Open Dialog Box
    CustomConsole("-----------handleSearchOrderId----------------");
    let finalOrderId = "";
    if ((props && props.length) || orderId) {
      finalOrderId = props ? props : "BTORD-" + orderId;
    }
    if (finalOrderId.length) {
      //Hit API Here
      CustomConsole("-----------handleSearchOrderId----------------");
      const reqObj = {
        delivery_from_date: moment().format("YYYY-MM-DD"),
        delivery_to_date: moment().format("YYYY-MM-DD"),
        page_size: 1,
        page_number: 1,
        order_ids: [finalOrderId],
      };
      dispatch(GetNewSearchOrderDetails(reqObj));
      setTimeout(() => {
        dispatch(GetDeliverySlotsList());
      }, 1000);
    } else {
      toast.error("Please Enter Valid Order Id", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  React.useEffect(() => {
    if (allOrderData.newallOrderDetails.length) {
      setIsEditOrderDetailsDialog(true);
    }
  }, [allOrderData.newallOrderDetails]);

  //Function to redirect user to Users Page
  const handleOnUserSearchClick = (props) => {
    CustomConsole("-- handleOnUserSearchClick --");
    CustomConsole(props);
    //Redirect to User's Page
    if (props.phone && props.phone.length) {
      const url = `/dashboard/UserDetailsMain?phoneNumber=${props.phone}`;
      history.push(url);
    }
  };

  // const handleChange = (field, value) => {
  //   setQueryParams({ ...queryParams, [field]: value });
  // };
  const handleChange = (field, value) => {
    const updatedValue = field === "zone_name" ? value?.toUpperCase() : value;
    setQueryParams({ ...queryParams, [field]: updatedValue });
    if (field === "zone_name") {
      setSelectedZoneName(updatedValue);
    }
  };

  //Function To Map the Data properly to save it in Excel
  const csvLink = {
    filename:
      "DeliveryReport" + moment().tz(TZ).format("YYYY-MM-DD HH:mm:ss") + ".csv",
    data: csvFileData,
  };
  //function to handel download csv file
  function toCSV(orderDetails) {
    const result = [];
    if (orderDetails && orderDetails.length) {
      for (const data of orderDetails) {
        const typeOfDiscount =
          data.packaged_drinking_water.water_drop_details &&
          data.packaged_drinking_water.water_drop_details.is_valid &&
          data.packaged_drinking_water.coupon_code_details &&
          data.packaged_drinking_water.coupon_code_details.is_valid
            ? "WATER DROPS & PROMOCODE"
            : (data.packaged_drinking_water.water_drop_details &&
                data.packaged_drinking_water.water_drop_details.is_valid) ||
              (data.packaged_drinking_water.used_Water_Drops &&
                data.packaged_drinking_water.used_Water_Drops > 0 &&
                data.packaged_drinking_water.isWaterDropUsed)
            ? "WATER DROPS"
            : (data.packaged_drinking_water.coupon_code_details &&
                data.packaged_drinking_water.coupon_code_details.is_valid) ||
              (data.packaged_drinking_water.promo_label &&
                data.packaged_drinking_water.promo_label.length &&
                data.packaged_drinking_water.promo_discount_price &&
                data.packaged_drinking_water.promo_discount_price > 0)
            ? "PROMOCODE"
            : "-";
        let orderProducts = {};
        if (!data.packaged_drinking_water.is_invalid) {
          let orderQtyname =
            data.packaged_drinking_water.selected_container_capacity +
            "-" +
            data.packaged_drinking_water.selected_container_material +
            `-order_quantity (1/pack)`;
          let returnQtyname =
            data.packaged_drinking_water.selected_container_capacity +
            "-" +
            data.packaged_drinking_water.selected_container_material +
            `-return_quantity (Units)`;
          orderProducts[orderQtyname] =
            data.packaged_drinking_water.twentyltr_capacity_qty;
          orderProducts[returnQtyname] =
            data.packaged_drinking_water.empty_container_qty;
        }
        for (const product of data.products) {
          let orderQtyname =
            product.capacity +
            "-" +
            product.material +
            `-order_quantity (${product.sku_qty}/pack)`;
          let returnQtyname =
            product.capacity +
            "-" +
            product.material +
            `-return_quantity (Units)`;
          orderProducts[orderQtyname] = product.quantity;
          orderProducts[returnQtyname] = product.return;
        }
        result.push({
          order_id: data.order_id,
          otp: data.delivery_otp,
          pincode: data.pincode,
          areaname: data.areaname,
          delivery_address:
            data.delivery_address.address_line1 +
            "\n" +
            data.delivery_address.address_line2 +
            "\n" +
            data.delivery_address.address_line3 +
            "\n" +
            data.delivery_address.landmark +
            "\n" +
            data.delivery_address.pincode +
            "\n" +
            data.delivery_address.service_area +
            "\n" +
            "Contact Person: " +
            data.delivery_contact_name +
            ` - ${data.delivery_contact_phone}`,
          booking_date: data.booking_date,
          booking_time: data.booking_time,
          delivery_date: data.delivery_date,
          delivery_time: data.delivered_on,
          order_type: data.order_type,
          order_status: data.order_status_name,
          ...orderProducts,
          delivery_slot: data.delivery_slot,
          consumer_name: data.consumer_name,
          consumer_phone: data.consumer_phone,
          consumer_email: data.consumer_email,
          vehicle_id: data.tanker_id,
          delivery_contact_name: data.delivery_contact_name,
          delivery_contact_phone: data.delivery_contact_phone,
          payment_method: data.payment_method,
          payment_status: data.payment_status_name,
          "order_price(With_Discount)": data.final_price,
          discount: data.discount,
          discount_type: typeOfDiscount,
          supplier_name: data.supplier_name,
          driver_name:
            data.driver_name && data.driver_name.length
              ? data.driver_name
              : data.drivers && data.drivers.length
              ? data.drivers[0].fullname
              : "-",
          driver_phone:
            data.driver_phone && data.driver_phone.length
              ? data.driver_phone
              : data.drivers && data.drivers.length
              ? data.drivers[0].phone
              : "-",
          payment_details:
            data.payment_details && Object.keys(data.payment_details).length
              ? data.payment_details.transaction_id +
                "\n" +
                moment(data.payment_details.transaction_ts)
                  .add(330, "minutes")
                  .format("YYYY-MM-DD HH:MM:SS")
              : "-",
          last_updated_at: data.last_updated_at,
          last_updated_by_name: data.updated_by_name,
          last_updated_by_phone: data.updated_by_phone,
          last_updated_by_email: data.updated_by_email,
          cancel_reason: data.cancel_reason,
        });
      }
    }
    setCsvFileData(result);
  }

  //function to handel orderid change
  const handleChangeOrderId = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    // if (e.target.value === "" || re.test(e.target.value)) {
    //   setOrderId(e.target.value);
    // }
    // if (/^\d*$/.test(e.target.value)) {
    //   setOrderId(e.target.value);
    // }
    setOrderId(e.target.value);
  };

  //function to handel page size change
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const handelFilterDialog = () => {};

  //function to handel clear filter
  const handelClearFilter = () => {
    setQueryParams((prev) => ({
      ...prev,
      pincode: "" || undefined,
      locality: "" || undefined,
      district: "" || undefined,
      country: "INDIA",
      state: "" || undefined,
      page_size: 25,
      page_number: 1,
    }));
    setSelectedZoneName("");
    setSelectedState("");
    setSelectedCountry("");
    setOrderStatus(0);
    setOrderType("None");
    setPincode(0);
    setFilterhouseCity("");
    setConsumerId(undefined);
    setProduct(" ")
    setClearFilterFlag(true);
  };

  //Get the Order Details on Change in PageNumber or Pagesize or sortBy
  React.useEffect(() => {
    if (clearFilterFlag) {
      OrderDetailsAndSummaryApi();
      setClearFilterFlag(false);
    }
  }, [clearFilterFlag]);

  //function to handel toggle option for filter
  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box>
      <Box
        sx={{
          borderRadius: ".5rem",
          display: "flex",
          justifyContent: "space-evenly",
          boxShadow: 2,
          padding: ".2rem",
          overflowX: "auto",
          width: "100%",
          gap: 2,
          scrollbarWidth: "thin",
          scrollBehavior: "smooth",
        }}
      >
        {!isOpen && (
          <Box>
            <IconButton onClick={onToggle} sx={{ size: "small" }}>
              <img
                alt="Bookwater"
                src={Filter}
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
          <TextField
            label="Deliveries from"
            variant="standard"
            value={fromDate}
            type="date"
            onChange={(e) => setFromDate(e.target.value)}
            size="small"
          />
          <TextField
            label="Deliveries till"
            variant="standard"
            value={toDate}
            type="date"
            size="small"
            onChange={(e) => setToDate(e.target.value)}
          />

          <ManageSearchIcon
            sx={styles.searchDateIcon}
            onClick={() => OrderDetailsAndSummaryApi()}
          />
        </Box>
        <Box sx={{ minWidth: "8rem" }}>
          <TextField
            label="Search order by ID"
            variant="standard"
            value={parseInt(orderId)}
            type="number"
            size="small"
            onChange={handleChangeOrderId}
            InputProps={{
              startAdornment: "BTORD-",
              endAdornment: (
                <SearchIcon
                  size="small"
                  sx={styles.searchOrderUserIcon}
                  onClick={() => handleSearchOrderId()}
                />
              ),
            }}
          />
        </Box>
        <Box sx={{ minWidth: "12rem" }}>
          <TextField
            error={isUserSearchValid}
            size="small"
            label="Search user phone/email"
            variant="standard"
            type="text"
            value={user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
            InputLabelProps={{
              sx: {
                fontSize: ".9rem",
              },
            }}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  sx={styles.searchOrderUserIcon}
                  onClick={() => handleSeacrhUserOrders()}
                />
              ),
            }}
            // helperText={isUserSearchValid ? "Invalid entry." : ""}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", marginTop: ".5rem" }}>
        {isOpen && (
          <Box
            sx={{
              width: { xs: "100%", md: isOpen ? "14rem" : "3rem" },
              transition: "width 0.3s ease",
              overflow: isOpen ? "visible" : "hidden",
            }}
          >
            {/* Filter Options for Orders Table */}
            <OrdersFilterData
              orderType={orderType}
              orderTypes={orderTypes}
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
              orderStatusDetails={orderStatusDetails}
              OrderDetailsAndSummaryApi={OrderDetailsAndSummaryApi}
              setOrderType={setOrderType}
              selectedState={selectedState}
              setFilterhouseCity={setFilterhouseCity}
              setSelectedCountry={setSelectedCountry}
              setSelectedState={setSelectedState}
              selectedCountry={selectedCountry}
              product={product}
              setProduct={setProduct}
              setPincode={setPincode}
              pincode={pincode}
              queryParams={queryParams}
              handleChange={handleChange}
              products={products}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handelClearFilter={handelClearFilter}
            />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: isOpen ? "75%" : "95%" },
          }}
        >
          <OrderSummary
            orderDetailsList={orderDetailsList}
            orderSummary={orderSummary}
          />

          {/* Orders Table */}
          <Box
            sx={{
              ...styles.paperCommon,
            }}
          >
            <CoustomPagiation
              setPageNumber={setPageNumber}
              handlePageSizeChange={handlePageSizeChange}
              pageNumber={pageNumber}
              handelFilterDialog={handelFilterDialog}
              pageSize={pageSize}
              data={orderList}
              filterButton={false}
              csvLink={csvLink}
              toCSV={toCSV}
            />
            <OrdersPageTabelView
              tableHeader={tableHeader}
              orderList={orderList}
              handleOnUserSearchClick={handleOnUserSearchClick}
              handleSearchOrderId={handleSearchOrderId}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RegularOrders;
