import React from "react";
import {
  Box,
  Typography,
  Collapse,
  IconButton,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import CustomConsole from "../../CustomConsole";

function RegularOrdersTable(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const driverDetails = {
    name:
      row.driver_name && row.driver_name.length
        ? row.driver_name
        : row.drivers && row.drivers.length
        ? row.drivers[0].fullname
        : "",
    phone:
      row.driver_phone && row.driver_phone.length
        ? row.driver_phone
        : row.drivers && row.drivers.length
        ? row.drivers[0].phone
        : "",
  };
  const typeOfDiscount =
    row.packaged_drinking_water.water_drop_details &&
    row.packaged_drinking_water.water_drop_details.is_valid &&
    row.packaged_drinking_water.coupon_code_details &&
    row.packaged_drinking_water.coupon_code_details.is_valid
      ? "WATER DROPS & PROMOCODE"
      : (row.packaged_drinking_water.water_drop_details &&
          row.packaged_drinking_water.water_drop_details.is_valid) ||
        (row.packaged_drinking_water.used_Water_Drops &&
          row.packaged_drinking_water.used_Water_Drops > 0 &&
          row.packaged_drinking_water.isWaterDropUsed)
      ? "WATER DROPS"
      : (row.packaged_drinking_water.coupon_code_details &&
          row.packaged_drinking_water.coupon_code_details.is_valid) ||
        (row.packaged_drinking_water.promo_label &&
          row.packaged_drinking_water.promo_label.length &&
          row.packaged_drinking_water.promo_discount_price &&
          row.packaged_drinking_water.promo_discount_price > 0)
      ? "PROMOCODE"
      : "-";
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}

          // sx={{ color: "blue", cursor: "pointer" }}
        >
          {props.index}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}
          onClick={() => props.handleOpenOrderDetails(row.order_id)}
        >
          {row.order_id}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color: row.delivery_otp_type.length ? "gray" : "brown",
            textDecoration: row.delivery_otp_type.length
              ? "line-through"
              : "none",
          }}
        >
          {row.delivery_otp ? row.delivery_otp : "-"}
        </TableCell>
        <TableCell align="center">{row.pincode}</TableCell>
        <TableCell align="center">{row.areaname}</TableCell>
        <TableCell align="center">
          {row?.packaged_drinking_water?.twentyltr_capacity_qty}
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: "bold" }}>
          {row.delivery_slot ? row.delivery_slot : "-"}
        </TableCell>{" "}
        <TableCell align="center">
          {row.delivery_contact_name}
          <br />
          {row.delivery_contact_phone}
          <br />
          {row.delivery_address.address_line1}
          <br />
          {row.delivery_address.address_line2}
          <br />
          {row.delivery_address.address_line3}
        </TableCell>
        <TableCell align="center">{row?.tanker_id}</TableCell>
        {/* <TableCell align="center">
          {driverDetails?.name}
          <br />
          {driverDetails?.phone}
        </TableCell> */}
        <TableCell
          align="center"
          sx={{
            color: "black",
            // textDecoration: driverDetails.phone.length
            //   ? "none"
            //   : "line-through",
            cursor: "pointer",
          }}
          // onClick={() =>
          //   driverDetails.phone.length
          //     ? props.handleOnUserSearchClick({
          //         name: driverDetails.name,
          //         phone: driverDetails.phone,
          //         email: "",
          //       })
          //     : ""
          // }
        >
          {driverDetails.name ? driverDetails.name : "-"}
          <br />
          {driverDetails.phone ? driverDetails.phone : "-"}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            fontWeight: "bold",
            color:
              row.order_type === "INSTA"
                ? "#AE6908"
                : row.order_type === "SUBSCRIBED"
                ? "#088769"
                : row.order_type === "REGULAR"
                ? "#1CB9BC"
                : "#9165A9",
          }}
        >
          {row.order_type}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color:
              row.order_status_name === "CANCELLED" ||
              row.order_status_name === "REJECTED"
                ? "red"
                : row.order_status_name === "ONGOING"
                ? "#450B7E"
                : row.order_status_name === "DELIVERED"
                ? "green"
                : "brown",
          }}
        >
          {row.order_status_name}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            color:
              row.consumer_phone && row.consumer_phone.length ? "blue" : "gray",
            textDecoration:
              row.consumer_phone && row.consumer_phone.length
                ? "none"
                : "line-through",
            cursor: "pointer",
          }}
          onClick={() =>
            row.consumer_phone && row.consumer_phone.length
              ? props.handleOnUserSearchClick({
                  name: row.consumer_name,
                  phone: row.consumer_phone,
                  email: row.consumer_email,
                })
              : ""
          }
        >
          {row.consumer_name ? row.consumer_name : "-"}
          <br />
          {row.consumer_phone}
          <br />
          {row.consumer_email}
        </TableCell>{" "}
        <TableCell align="center">{row.payment_method}</TableCell>
        <TableCell align="center">{row.payment_status_name}</TableCell>
        <TableCell align="center">{row.final_price}</TableCell>
        <TableCell align="center">{row.discount}</TableCell>
        <TableCell align="center" sx={{ color: "#840587", fontWeight: "bold" }}>
          {typeOfDiscount}
        </TableCell>
        <TableCell align="center">
          {row.supplier_name ? row.supplier_name : "-"}
        </TableCell>
        <TableCell align="center">
          {row.booking_date} <br /> {row.booking_time}
        </TableCell>
        <TableCell align="center">
          {row.delivery_date} <br />
          {row.delivered_on}
        </TableCell>
        {/* Payment Details */}
        <TableCell align="center">
          {row.payment_details &&
          Object.keys(row.payment_details).length > 0 ? (
            <>
              {row.payment_details.transaction_id} <br />
              {moment(row.payment_details.transaction_ts)
                // .add(330, "minutes")
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD HH:mm:ss")}
            </>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="center">{row.last_updated_at}</TableCell>
        <TableCell
          align="center"
          sx={{
            color:
              row.updated_by_phone && row.updated_by_phone.length
                ? "blue"
                : "gray",
            textDecoration:
              row.updated_by_phone && row.updated_by_phone.length
                ? "none"
                : "line-through",
            cursor: "pointer",
          }}
          onClick={() =>
            row.updated_by_phone && row.updated_by_phone.length
              ? props.handleOnUserSearchClick({
                  name: row.updated_by_name,
                  phone: row.updated_by_phone,
                  email: row.updated_by_email,
                })
              : ""
          }
        >
          {row.updated_by_name}
          <br />
          {row.updated_by_phone}
          <br />
          {row.updated_by_email}
        </TableCell>
        <TableCell align="center" sx={{ color: "red" }}>
          {row.cancel_reason}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Products
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {!row.packaged_drinking_water?.damage_invoice?.length && (
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Damaged Qty
                      </TableCell>
                    )}
                    <TableCell sx={{ fontWeight: "bold" }}>Capacity</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Material</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Order Quantity(packs)
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Return Quantity(units)
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      SKU Size
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Scanned Empty
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Scanned Filled
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!row.packaged_drinking_water.is_invalid ? (
                    <TableRow key={100}>
                      {!row.packaged_drinking_water?.damage_invoice?.length && (
                        <TableCell component="th" scope="row">
                          {row.packaged_drinking_water?.damage_invoice?.length
                            ? "--"
                            : row.packaged_drinking_water
                                ?.consumer_damaged_qty || 0}
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {
                          row.packaged_drinking_water
                            .selected_container_capacity
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          row.packaged_drinking_water
                            .selected_container_material
                        }
                      </TableCell>
                      <TableCell align="center">
                        {row.packaged_drinking_water.twentyltr_capacity_qty}
                      </TableCell>
                      <TableCell align="center">
                        {row.packaged_drinking_water.empty_container_qty}
                      </TableCell>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        {row.packaged_drinking_water.received_empty_cans}
                      </TableCell>
                      <TableCell align="center">
                        {row.packaged_drinking_water.submitted_filled_cans}
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {row.products?.length &&
                    row.products.map((product, key) => (
                      <TableRow key={key}>
                        {!row.packaged_drinking_water?.damage_invoice
                          ?.length && (
                          <TableCell component="th" scope="row">
                            {product.consumer_damaged_qty || "--"}
                          </TableCell>
                        )}
                        <TableCell component="th" scope="row">
                          {product.capacity}
                        </TableCell>
                        <TableCell align="center">{product.material}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {product.quantity}
                          <br />({product.quantity} * Pack Of {product.sku_qty}={" "}
                          {product.quantity * product.sku_qty})
                        </TableCell>
                        <TableCell align="center">{product.return}</TableCell>
                        <TableCell align="center">{product.sku_qty}</TableCell>
                        <TableCell align="center">
                          {product.consumer_submitted_empty}
                        </TableCell>
                        <TableCell align="center">
                          {product.consumer_received_filled}
                        </TableCell>
                      </TableRow>
                    ))}
                  {row.packaged_drinking_water?.damage_invoice?.map(
                    (product, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          Consumer Damaged Product
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {product.capacity}
                        </TableCell>
                        <TableCell align="center">{product.material}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {product.quantity}
                          <br />({product.quantity} * Pack Of {product.sku_qty}={" "}
                          {product.quantity * product.sku_qty})
                        </TableCell>
                        <TableCell align="center">0</TableCell>
                        <TableCell align="center">{product.sku_qty}</TableCell>
                        <TableCell align="center">0</TableCell>
                        <TableCell align="center">0</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default RegularOrdersTable;
