/**
 * @copycenter Copycenter (c) 2024 Bookwater
 * @author Gowtham Prasath
 * @date 08-01-2025
 * @description Component to get the list of PDE Order details
 */

import { Box, Card, Grid, Typography } from "@mui/material";
import { styles } from "../../Pages/AdminOrders/ordersStyle";
import SyncIcon from "@mui/icons-material/Sync";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";
import cssObject from "../AppMeta/appStyles";
export function OrderSummary({ orderDetailsList, orderSummary }) {
  console.log(orderSummary);
  return (
    <>
      <Box
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          display: "flex",
          flexWrap: "nowrap",
          scrollbarWidth: "none",
          borderRadius: ".5rem",
          padding: "0 .5rem .5rem .5rem",
          scrollbarWidth: "thin",
        }}
      >
        {/* Order & Products Summary */}
        {orderDetailsList.pdwOrderSummary &&
        orderDetailsList.pdwOrderSummary.orders &&
        orderDetailsList.pdwOrderSummary.products &&
        orderDetailsList.pdwOrderSummary.products.length &&
        Object.keys(orderDetailsList.pdwOrderSummary.orders).length ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            {[
              // Array of order summary cards
              {
                header: "Total",
                orderData: [
                  {
                    subheader: "Ongoing",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_ongoing,
                  },
                  {
                    subheader: "Delivered",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_delivered,
                  },
                  {
                    subheader: "Total",
                    value: orderDetailsList.pdwOrderSummary.orders.total_orders,
                  },
                ],
              },
              {
                header: "Ongoing",
                orderData: [
                  {
                    subheader: "Total",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_ongoing,
                  },
                  {
                    subheader: "Insta",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing.insta,
                  },
                  {
                    subheader: "Subscribed",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing
                        .subscribed,
                  },
                  {
                    subheader: "Regular",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing.regular,
                  },
                  {
                    subheader: "Postpaid",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.ongoing.postpaid,
                  },
                ],
              },
              {
                header: "Delivered",
                orderData: [
                  {
                    subheader: "Total",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.total_delivered,
                  },
                  {
                    subheader: "Insta",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered.insta,
                  },
                  {
                    subheader: "Subscribed",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered
                        .subscribed,
                  },
                  {
                    subheader: "Regular",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered.regular,
                  },
                  {
                    subheader: "Postpaid",
                    value:
                      orderDetailsList.pdwOrderSummary.orders.delivered
                        .postpaid,
                  },
                ],
              },
            ].map((data, key) => (
              <Box
                key={key}
                sx={{
                  flex: 1,
                  minWidth: "10rem",
                  marginRight: 2,
                }}
              >
                <Card
                  sx={{
                    ...styles.osCard,
                    backgroundImage: styles.postpaidBgImage,
                    background: cssObject.color.waterBlue,
                    height: "9rem",
                  }}
                >
                  <Typography
                    sx={{
                      ...styles.osCardHeadText,
                      ...styles.osTextBox,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {key === 0 ? (
                      ""
                    ) : key === 1 ? (
                      <SyncIcon sx={{ color: "brown" }} />
                    ) : (
                      <LocalShippingIcon sx={{ color: "green" }} />
                    )}
                    {data.header}
                  </Typography>

                  {data.orderData.map((od, index) => (
                    <Box sx={styles.osTextBox} key={index}>
                      <Typography sx={styles.osTextHead}>
                        &nbsp;&nbsp; {od.subheader} :
                      </Typography>
                      <Typography sx={styles.osTextAnswer}>
                        {od.value}
                      </Typography>
                    </Box>
                  ))}
                </Card>
              </Box>
            ))}
          </Box>
        ) : null}

        {/* Products Card */}
        <Box
          sx={{
            flex: 1,
            minWidth: "28rem",
            marginRight: 5,
          }}
        >
          <Card
            sx={{
              ...styles.osCard,
              background: cssObject.color.waterBlue,
              height: "9rem",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
          >
            <Typography
              sx={{
                ...styles.osCardHeadText,
                ...styles.osTextBox,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ProductionQuantityLimitsIcon sx={{ color: "blue" }} /> Products
            </Typography>

            {orderDetailsList.pdwOrderSummary.products.map((product, index) => (
              <Box
                sx={{ ...styles.osTextBox, marginBottom: "10px" }}
                key={index}
              >
                <Typography sx={styles.osTextHead}>
                  &nbsp;&nbsp; {product.product_name} :
                </Typography>
                <Typography sx={styles.osTextAnswer}>
                  <span
                    style={{
                      ...styles.osTextAnswer,
                      color: "blue",
                      border: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    T: {product.total}
                  </span>{" "}
                  <span
                    style={{
                      ...styles.osTextAnswer,
                      color: "green",
                      border: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    D: {product.delivered}
                  </span>{" "}
                  <span
                    style={{
                      ...styles.osTextAnswer,
                      color: "red",
                      border: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    O: {product.ongoing}
                  </span>
                </Typography>
              </Box>
            ))}
            <Box sx={{ ...styles.osTextBox }}>
              <Typography sx={styles.osTextHead}>Note:-</Typography>
              <Typography sx={styles.osTextHead}>
                T: Total, D: Delivered, O: Ongoing
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}
