import { Add, DeleteForever, Email } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useProductImages from "../../Components/Custom/useProductImages";
import ImageViewer from "../../Components/Custom/imageLoader";
import { CheckUserAvailableDetails } from "../Redux/Actions/AdminSettingsAction";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function AddGiftCard(props) {
  const { pathname, search } = useLocation();
  const pageTitleSideBar = pathname.split("/dashboard/");
  const dispatch = useDispatch();
  const [userPhone, setUserPhone] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userStatus, setuserStatus] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [isPasswordRequired, setIsPasswordRequired] = React.useState(false);
  const [loadWalletDirectly, setLoadWalletDirectly] = React.useState(false);
  const [redeemthrough, setRedeemthrough] = React.useState("WALLET");
  const [addGreeting, setAddGreeting] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [ownWishes, setOwnWishes] = React.useState("");
  const [listOfGrettings, setListOfGrettings] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [storeAllImages, setStoreAllImages] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [userVerifyFlag, setUserVerifyFlag] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const { images, isLoading, error } = useProductImages(
    storeAllImages,
    "GiftCardImages"
  );

  React.useEffect(() => {
    console.log(
      "props.settingsDetails",
      props.settingsDetails.getGiftCarddata?.gift_card_settings
    );
    const storeimages =
      props.settingsDetails.getGiftCarddata?.gift_card_settings?.greetings?.flatMap(
        (data) => {
          return data.images;
        }
      );
    console.log("storeimages", storeimages);
    setStoreAllImages(storeimages);
    setListOfGrettings(
      props.settingsDetails.getGiftCarddata?.gift_card_settings?.greetings
    );
  }, [props.settingsDetails.getGiftCarddata]);

  const handleAddGreeting = () => {
    setAddGreeting(true);
  };
  const handleCLickCategory = (data) => {
    console.log("datas", data);
    setSelectedCategory(data);
    // setIsClicked(!isClicked); // Toggle the clicked state
  };

  const handleSelectedImage = (image) => {
    console.log("selectedImage", image);
    setSelectedImage(image);
  };
  const handleSubmit = () => {
    if (amount !== 0 && amount !== "") {
      props.handleSubmitGiftCard({
        isLoadWalletDirectly: loadWalletDirectly,
        amount: amount,
        isPasswordRequired: isPasswordRequired,
        redeemThrough: redeemthrough,
        ownWishes: ownWishes,
        selectedCategory: selectedCategory?.category,
        selectedGreetingImage: selectedImage,
        email: userEmail || undefined,
        phone: userPhone || undefined,
      });
    } else {
      toast.dark("PLease enter proper amount for gift card", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
  const handleCloseGitCard = () => {
    props.handleCloseGitCard();
  };

  // Function to handle verify user
  const handelVerifyButtonClick = () => {
    let reqObj = {
      phone: userPhone === "" ? undefined : userPhone,
      email: userEmail === "" ? undefined : userEmail,
    };
    dispatch(CheckUserAvailableDetails(reqObj)).then((data) => {
      if (!data?.error) {
        setUserData(data?.data);
        setErrorMsg(data?.msg);
        setuserStatus(data?.isOurUser);
        setUserPhone(userPhone);
        setUserEmail(userEmail);
      } else if (data?.error) {
        setUserData(data?.data);
        setErrorMsg(data?.msg);
        setuserStatus(data?.isOurUser);
      }
    });
  };

  //function to set user details based on user selected option
  const handelSelectedUserClick = (data) => {
    setUserPhone(data?.phone);
    setUserEmail(data?.email);
    setUserVerifyFlag(true);
  };

  //useeffect to get userdetails
  React.useEffect(() => {
    if (userVerifyFlag) {
      handelVerifyButtonClick();
      setUserVerifyFlag(false);
    }
  }, [userVerifyFlag]);

  //function to handel user phone change
  const handelUserPhone = (event) => {
    setUserPhone(event.target.value);
    setErrorMsg("");
    setuserStatus(false);
    setLoadWalletDirectly(false)
  };

  //function to handel user email change
  const handelUserEmail = (event) => {
    setUserEmail(event.target.value);
    setErrorMsg("");
    setuserStatus(false);
    setLoadWalletDirectly(false)
  };
  return (
    <Dialog open={props.openCreateGiftCard}>
      <DialogTitle textAlign="center">Create Gift Card</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box sx={{ display: "flex" }}>
            <Typography fontWeight="bold">Maximum Amount :</Typography>
            <Typography>
              {props.settingsDetails.getGiftCarddata?.gift_card_settings
                ?.maximum_amount || 0}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography fontWeight="bold">Minimum Amount :</Typography>
            <Typography>
              {props.settingsDetails.getGiftCarddata?.gift_card_settings
                ?.minimum_amount || 0}
            </Typography>
          </Box>
          {props.isBookwaterConsumer === "NO" ? (
            <>
              {userData?.length > 0 && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {!userStatus && (
                      <>
                        {userData?.map((data) => (
                          <Box
                            sx={{
                              p: 1,
                              border: "1px dashed grey",
                              display: "flex",
                              flexDirection: "column",
                              flexWrap: "wrap",
                              cursor: "pointer",
                            }}
                            onClick={() => handelSelectedUserClick(data)}
                          >
                            <Typography
                              sx={{
                                color: "#038ec2",
                                fontSize: ".8rem",
                                fontWeight: "500",
                              }}
                            >
                              Phone: {data?.phone}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#038ec2",
                                fontSize: ".8rem",
                                fontWeight: "500",
                              }}
                            >
                              Email: {data?.email}
                            </Typography>
                          </Box>
                        ))}{" "}
                      </>
                    )}
                  </Box>
                </>
              )}
              <TextField
                label="Phone Number*"
                size="small"
                value={userPhone}
                autoFocus
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onChange={handelUserPhone}
                // sx={{ mt: "10px" }}
              />
              <TextField
                label="Email*"
                size="small"
                value={userEmail}
                autoFocus
                onChange={handelUserEmail}
                sx={{ mt: "10px" }}
              />
              {errorMsg && (
                <Typography
                  sx={{
                    color: "#038ec2",
                    fontSize: ".8rem",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {errorMsg}
                </Typography>
              )}
              <Button
                variant="contained"
                size="small"
                onClick={handelVerifyButtonClick}
              >
                Verify
              </Button>
            </>
          ) : (
            ""
          )}
          <TextField
            label="Amount*"
            size="small"
            value={amount}
            autoFocus
            type="number"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 7);
            }}
            onChange={(event) => setAmount(event.target.value)}
            sx={{ mt: "10px" }}
          />
          <Box sx={{ border: "2px solid black", padding: "10px" }}>
            <FormControl>
              <FormLabel sx={{ fontWeight: "bold" }}>Redeem Through</FormLabel>
              <RadioGroup
                row
                value={redeemthrough}
                onChange={(event) => setRedeemthrough(event.target.value)}
              >
                <FormControlLabel
                  value="WALLET"
                  control={<Radio />}
                  label="Wallet"
                />
                <FormControlLabel
                  value="WATER_DROPS"
                  control={<Radio />}
                  label="Waterdrops"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              gap: "10px",
              border: "2px solid black",
              padding: "5px",
            }}
          >
            <Typography sx={{ mt: "8px", fontWeight: "bold" }}>
              Password Required
            </Typography>
            <FormControlLabel
              control={<Switch checked={isPasswordRequired} />}
              label={
                isPasswordRequired ? (
                  <Typography
                    sx={{
                      color: "black",
                    }}
                  >
                    Yes
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "black",
                    }}
                  >
                    No
                  </Typography>
                )
              }
              onChange={(event) => setIsPasswordRequired(event.target.checked)}
            />
          </Box>
          {userStatus ||
          pageTitleSideBar[1] === "UserDetailsMain" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                gap: "10px",
                border: "2px solid black",
                padding: "5px",
              }}
            >
              <Typography sx={{ mt: "8px", fontWeight: "bold" }}>
                Load Wallet Directly
              </Typography>
              <FormControlLabel
                control={<Switch checked={loadWalletDirectly} />}
                label={
                  loadWalletDirectly ? (
                    <Typography
                      sx={{
                        color: "black",
                      }}
                    >
                      Yes
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "black",
                      }}
                    >
                      No
                    </Typography>
                  )
                }
                onChange={(event) =>
                  setLoadWalletDirectly(event.target.checked)
                }
              />
            </Box>
          ) : (
            ""
          )}
          <Button variant="contained" size="small" onClick={handleAddGreeting}>
            <Add /> Greeting
          </Button>
          {addGreeting ? (
            <>
              <TextField
                label="Own Wishes"
                size="small"
                value={ownWishes}
                onChange={(event) => setOwnWishes(event.target.value)}
                sx={{ mt: "10px" }}
              />
              <Box mt={4}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  List Of GREETINGS:
                </Typography>

                <Box
                  sx={{
                    m: 2,
                    display: "flex",
                    // justifyContent: "space-around",
                    flexDirection: "row",
                    gap: "30px",
                    overflow: "auto",
                  }}
                >
                  {listOfGrettings !== undefined &&
                    listOfGrettings !== null &&
                    listOfGrettings?.map((greeting) => (
                      <div key={greeting.category}>
                        <Paper
                          onClick={() => handleCLickCategory(greeting)}
                          elevation={20}
                          sx={{
                            padding: "10px",
                            cursor: "pointer",
                            backgroundColor:
                              greeting.category === selectedCategory.category
                                ? "#5dade2"
                                : "#d6eaf8",
                            border:
                              greeting.category === selectedCategory.category
                                ? "2px solid #21618c"
                                : "none",
                            color:
                              greeting.category === selectedCategory.category
                                ? "white"
                                : "black",
                          }}
                        >
                          {greeting.category}
                        </Paper>
                      </div>
                    ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    // flexWrap: "wrap",
                    gap: "10px",
                    justifyContent: "flex-start",
                    // ml: 2,
                    height: "100px",
                    width: "100%",
                    overflowX: "auto",
                  }}
                >
                  {selectedCategory.images !== undefined &&
                    selectedCategory.images !== null &&
                    selectedCategory.images?.map((data) => {
                      return (
                        <Box
                          onClick={() => handleSelectedImage(data)}
                          sx={{
                            minWidth: "5rem",
                            minHeight: "5rem",
                            backgroundColor:
                              data === selectedImage ? "blue" : "#d6eaf8",
                            p: "5px",
                          }}
                        >
                          <ImageViewer filename={data} />
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={handleCloseGitCard}
        >
          close
        </Button>
        {pageTitleSideBar[1] === "UserDetailsMain" ||
        pageTitleSideBar[1] === "settings" ? (
          <Button variant="contained" size="small" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AddGiftCard;
