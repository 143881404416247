import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

export const AdminAddUser = (
  fullname,
  phone,
  email,
  password,
  roleId,
  supplierphone,
  selectedRoleName,
  referral
) => {
  CustomConsole(fullname);
  CustomConsole(phone);
  CustomConsole(email);
  CustomConsole(password);
  CustomConsole(roleId);
  CustomConsole(supplierphone);
  CustomConsole(selectedRoleName);
  CustomConsole(referral);
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  const reqObj = {
    email: email,
    fullname: fullname,
    is_email_verified: true,
    is_phone_verified: true,
    password: password,
    phone: phone,
    phone_code: "+91",
    role_id: roleId,
    status: true,
    supplier_emailphone: supplierphone,
    role_name: selectedRoleName,
    referral_code:referral
  };
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/SCM/admin/add_user`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.ADMIN_ADD_USER,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
          return response.data;
        } else {
          toast.dark("User Added Successfully", {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
          return response.data;
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      return error.response.data;
    }
  };
};

export const SearchUserAdmin = (email_phone) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/users?email_phone=${email_phone}`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SEARCH_USER_ADMIN,
          payload: response.data,
        });
        if (response.data.msg) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
        return response.data;
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

// export const ResetUserData = () => {
//   return (dispatch) => {
//     dispatch({
//       type: actionType.SEARCH_USER_ADMIN,
//       payload: { error: false, msg: "Data cleared", users: {} },
//     });
//   };
// };

export const GetRolesList = () => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/superAdmin/roles/getRolesList`);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_ROLES_LIST,
          payload: response.data,
        });
        CustomConsole("Error---");
        // if (response.data.msg) {
        //   toast.dark(response.data.msg, {
        //     position: "bottom-right",
        //     autoClose: 3000,
        //     type: "error",
        //   });
        // }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
export const ClearData = () => {
  return {
    type: actionType.CLEAR_DATA,
  };
};
