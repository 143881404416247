/**
 * @author Gowtham Prasath
 * @description Book Now
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 20-01-2024
 */

import { Box, Grid } from "@mui/material";
import CustomConsole from "../../CustomConsole";
import { Container } from "@mui/system";
import React from "react";
import AdminRolesTable from "./AdminRolesTable";
import AdminUsers from "../AdminUsers/AdminUsers";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import Alert from "@mui/material/Alert";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@mui/styles";
import ActiveUsersTable from "./activeUsersTabel";
import { useDispatch, useSelector } from "react-redux";
import { GetSetUpConfigAction } from "../Redux/Actions/adminBookingAction";
import { GetRolesList } from "../Redux/Actions/addUserAdminAction";

const useStyles = makeStyles({
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
    "& .MuiTab-root": {
      color: "white",
      borderRadius: "12px",
    },
    "& .Mui-selected": {
      color: "white",
    },
  },
});

function SuperAdmin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appMetaConfig = useSelector((state) => state.adminBookingData);
  const addUserAdminDetails = useSelector((state) => state.addUserAdminDetails);
  const [roles, setRoles] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);
  const [value, setValue] = React.useState("1");

  //function to get roles list
  React.useEffect(() => {
    dispatch(GetRolesList());
  }, []);

  //useeffect to get admin roles list
  React.useEffect(() => {
    if (addUserAdminDetails.rolesList.length > 0) {
      setRoles(addUserAdminDetails.rolesList);
    }
  }, [addUserAdminDetails]);

  //function to get appmeta config
  React.useEffect(() => {
    CustomConsole(appMetaConfig);
    if (appMetaConfig.setupConfigData.length > 0) {
      appMetaConfig.setupConfigData.map((data, key) => {
        if (data.key === "userDomainType") {
          setDepartmentList(data.value);
        }
      });
    }
  }, [appMetaConfig]);
  //function to handel tab value change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ? (
        <>
          {" "}
          <Container
            sx={{
              marginTop: "50px",
              padding: "0px",
            }}
          >
            <Grid container>
              <Box sx={{ width: "100%" }}>
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                    sx={{ background: "#6495ED", borderRadius: "1rem" }}
                    className={classes.tabUi}
                  >
                    <Tab label="Add Users" value="0" />
                    <Tab label="Roles List" value="1" />
                    <Tab label="Active Users" value="2" />
                  </TabList>
                  <TabPanel value="0">
                    <Grid item xs={12}>
                      <AdminUsers />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="1">
                    <Grid item xs={12}>
                      <AdminRolesTable
                        roles={roles}
                        departmentList={departmentList}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <ActiveUsersTable />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Container>
        </>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </Box>
  );
}

export default SuperAdmin;
