import { FileUpload } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { expense_type } from "../../Components/AppMeta/appMetaConfig";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
  GetRunningVehiclesList,
  GetVehiclesWithTrip,
} from "../Redux/Actions/supplierVehiclesActions";
import { UploadFile } from "../Redux/Actions/fileHandlingAction";

function VehicleAddOperatingLevelCostDialog(props) {
  console.log(props);
  const dispatch = useDispatch();
  const [selectedVehicle, setSelectedVehicle] = React.useState(null);
  console.log(selectedVehicle);
  const [stopSwitch, setStopSwitch] = React.useState(true);
  const [accountNumber, setAccountNumber] = React.useState("");
  const [selectedExpenseType, setSelectedExpenseType] = React.useState("");
  const [selectMonth, setSelectMonth] = React.useState("");
  const [paidDate, setPaidDate] = React.useState(
    moment().tz("Asia/Kolkata").format("YYYY-MM-DD")
  );
  const [purpose, setPurpose] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [seqid, setSeqid] = React.useState("");
  const [reciptupload, setReciptupload] = React.useState("");
  const [uploadReciptFile, setUploadReciptFile] = React.useState("");
  const [selectedSupplierId, setSelectedSupplierId] = React.useState(null);

  console.log(selectedSupplierId);
  const [startDate, setStartDate] = useState(
    moment().tz("Asia/Kolkata").format("YYYY-MM-DD")
  );
  console.log(reciptupload);
  const [uploadReciptBtn, setUploadReciptBtn] = React.useState(false);
  const [uploadFlags, setUploadFlags] = React.useState(false);
  const [nextDueDate, setNextDueDate] = useState();
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const [reciptUrl, setReciptUrl] = React.useState("");
  const fileHandler = useSelector((state) => state.fileHandler);
  const VehicleList = useSelector(
    (state) => state.vehicleManagement.vehicleTrips
  );

  const uploadPaymentRecipt = React.useRef(null);
  //function for handling file upload opreation
  const handleUploadRecipt = () => {
    uploadPaymentRecipt.current.click();
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 28,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  //useEffect for handling file upload operation
  React.useEffect(() => {
    if (uploadReciptFile !== "") {
      dispatch(UploadFile(uploadReciptFile));
      setUploadReciptBtn(true);
      setUploadReciptFile("");
      setUploadFlags(true);
    }
  }, [uploadReciptFile]);
  //file handler
  React.useEffect(() => {
    console.log(fileHandler);
    if (fileHandler.isFileUploaded === true && uploadReciptBtn === true) {
      setReciptUrl(fileHandler.fileName);
      setUploadReciptBtn(false);
    }
    if (
      fileHandler.error === false &&
      fileHandler.isFileUploaded === true &&
      uploadFlags === true
    ) {
      toast.success("Recipt  uploaded successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setUploadFlags(false);
    }
  }, [fileHandler]);

  React.useEffect(() => {
    if (props.openEditSingleOperationCost) {
      console.log(props.singleOperationCostDetails);
      setSeqid(props.singleOperationCostDetails.id);
      setSelectedExpenseType(props.singleOperationCostDetails.expense_type);
      setAmount(props.singleOperationCostDetails.amount);
      setStartDate(
        moment(props.singleOperationCostDetails.details?.start_date).format(
          "DD-MM-YYYY"
        )
      );
      setReciptUrl(props.singleOperationCostDetails.payment_receipt);
      setSelectedSupplierId({
        name: props.singleOperationCostDetails.supplier?.supplier_profile
          ?.company_name,
      });
      setAccountNumber(
        props.singleOperationCostDetails?.details?.account_number
      );
      setSelectedVehicle({
        name: props.singleOperationCostDetails.details?.tanker_id,
      });
      setPaidDate(
        moment(props.singleOperationCostDetails.paid_date)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD")
      );
      setNextDueDate(
        props.singleOperationCostDetails.due_date
          ? moment(props.singleOperationCostDetails.due_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD")
          : props.singleOperationCostDetails.next_due_date
          ? moment(props.singleOperationCostDetails.next_due_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD")
          : undefined
      );
      setPurpose(props.singleOperationCostDetails.details?.reason);
      setQuantity(props.singleOperationCostDetails.details?.quantity);
      setStartDate(props.singleOperationCostDetails.details?.startDate);
    }
  }, [props.openEditSingleOperationCost]);

  const handleCloseVehicleDialog = () => {
    props.handleCloseVehicleDialog();
  };
  const handleSubmitVehicleDialog = () => {
    if (
      selectedVehicle?.name ||
      props.singleOperationCostDetails.details?.tanker_id
    ) {
      props.handleSubmitVehicleDialog({
        selectedExpenseType: selectedExpenseType,
        amount: amount,
        paidDate: paidDate,
        reciptUrl: reciptUrl,
        nextDueDate: nextDueDate || undefined,
        purpose: purpose,
        startDate: startDate,
        quantity: quantity,
        tanker_id:
          selectedVehicle?.name ||
          props.singleOperationCostDetails.details?.tanker_id,
        seqid: seqid,
        accountNumber: accountNumber,
        edt_next_due_date: props.editNextDueDate,
        stop_expense: stopSwitch,
        ops_type: 300,
      });
    } else {
      toast.error("Please Select Vehicle", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  //function to get vehicles Details details based on supolier_id
  React.useEffect(() => {
    console.log(selectedSupplierId);
    if (selectedSupplierId?.user_id) {
      //   getDistricts({ country: selectedCountry });
      GetWareVehicleDetails({
        user_id: selectedSupplierId?.user_id,
      });
    }
  }, [selectedSupplierId]);

  //function to download vehicles details
  function GetWareVehicleDetails(data) {
    console.log("WareHouseDetails");
    console.log(data);
    setSelectedVehicle(null);
    setAvailableVehicles([]);
    dispatch(
      GetRunningVehiclesList({
        supplier_number: selectedSupplierId.supplier_number,
        ingnore_trip: true,
      })
    ).then((data) => {
      setAvailableVehicles(data?.data || []);
    });
  }
  return (
    <Dialog open={props.openAddVehicleCostDialog}>
      <DialogContent>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", mb: "15px" }}>
            <Typography
              sx={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {props.openEditSingleOperationCost === true ? "EDIT" : "ADD"}{" "}
              VEHICLE OPERATING COST
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              // flexWrap: "wrap",
              flexDirection: { md: "row", xs: "column" },
              gap: "10px",
              mb: "10px",
            }}
          >
            <Autocomplete
              disabled={
                !props.supplierDeliveries?.supplierList?.length ||
                props.editNextDueDate === "edit"
              }
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                (props.supplierDeliveries?.supplierList !== null &&
                  props.supplierDeliveries?.supplierList?.map((item, index) => {
                    return {
                      name:
                        item.supplier_name !== null ? item.supplier_name : "",
                      user_id: item.user_id,
                      supplier_number: item.phone,
                      supplier_id: item.id,
                    };
                  })) ||
                []
              }
              value={selectedSupplierId}
              autoHighlight
              getOptionLabel={(nameSearch) => `${nameSearch.name} `}
              onChange={(_, newValue) => {
                setSelectedSupplierId(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    props.supplierDeliveries?.supplierList !== undefined &&
                    props.supplierDeliveries?.supplierList !== null &&
                    props.supplierDeliveries?.supplierList?.length > 0
                      ? "Select Supplier"
                      : "No Supplier Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Autocomplete
              disabled={!availableVehicles?.length}
              id="country-select-demo"
              sx={{ width: 210, mb: "10px" }}
              options={
                availableVehicles?.map((item, index) => {
                  return {
                    name: item.tanker_id,
                    make: item.tanker_profile?.make,
                    model: item.tanker_profile?.model,
                    id: index,
                  };
                }) || []
              }
              autoHighlight
              value={selectedVehicle}
              getOptionLabel={(nameSearch) =>
                `${nameSearch.name} ${nameSearch.make} -${nameSearch.model}`
              }
              onChange={(_, newValue) => {
                setSelectedVehicle(newValue); // Set the selected  as the value
              }}
              renderOption={(props, nameSearch) => (
                <Box
                  component="li"
                  // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {nameSearch.name}-{nameSearch.make}-{nameSearch.model}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={
                    availableVehicles?.length
                      ? "Select Vehicle"
                      : "No Vehicles Found"
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: null, // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <FormControl sx={{ width: 220 }} size="small">
              <InputLabel>Select Expense Type</InputLabel>
              <Select
                disabled={
                  !selectedVehicle?.name || props.editNextDueDate === "edit"
                }
                value={selectedExpenseType}
                label="Select Expense Type"
                onChange={(event) => setSelectedExpenseType(event.target.value)}
              >
                <MenuItem value={expense_type.FUEL}>FUEL</MenuItem>
                <MenuItem value={expense_type.TRAFFIC_CHALLAN}>
                  TRAFFIC CHALLAN
                </MenuItem>
                <MenuItem value={expense_type.INSURANCE}>INSURANCE</MenuItem>
                <MenuItem value={expense_type.POLLUTION_CERTIFICATE}>
                  POLLUTION CERTIFICATE
                </MenuItem>
                <MenuItem value={expense_type.REPAIRS_DAMAGE}>
                  REPAIRS & DAMAGE
                </MenuItem>
                <MenuItem value={expense_type.MAINTENANCE}>
                  MAINTENANCE
                </MenuItem>
                <MenuItem value={expense_type.EMI}>EMI</MenuItem>
                <MenuItem value={expense_type.RECHARGE}>RECHARGE</MenuItem>
                <MenuItem value={expense_type.OTHERS}>OTHERS</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {selectedExpenseType === expense_type.FUEL ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                FUEL
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  sx={{ width: 100 }}
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value)}
                />
                <Button
                  onClick={handleUploadRecipt}
                  // variant="contained"
                  size="small"
                >
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.TRAFFIC_CHALLAN ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                TRAFFIC CHALLAN
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",

                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.INSURANCE ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                INSURANCE
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  // sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Start Date"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Next Due Date"
                  value={nextDueDate}
                  onChange={(event) => setNextDueDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.POLLUTION_CERTIFICATE ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                POLLUTION CERTIFICATE
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Start Date"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Next Due Date"
                  value={nextDueDate}
                  onChange={(event) => setNextDueDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.REPAIRS_DAMAGE ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                DAMAGE & REPAIR
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.MAINTENANCE ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                MAINTENANCE
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.EMI ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                EMI
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <FormControl sx={{ width: 220 }} size="small">
                  <InputLabel>Select Month</InputLabel>
                  <Select
                    value={selectMonth}
                    label="Select Month"
                    onChange={(event) => setSelectMonth(event.target.value)}
                  >
                    <MenuItem value={1}>JAN</MenuItem>
                    <MenuItem value={2}>FEB</MenuItem>
                    <MenuItem value={3}>MARCH</MenuItem>
                    <MenuItem value={4}>APRIL</MenuItem>
                    <MenuItem value={5}>MAY</MenuItem>
                    <MenuItem value={6}>JUNE</MenuItem>
                    <MenuItem value={7}>JULY</MenuItem>
                    <MenuItem value={8}>AUG</MenuItem>
                    <MenuItem value={9}>SEP</MenuItem>
                    <MenuItem value={10}>OCT</MenuItem>
                    <MenuItem value={11}>NOV</MenuItem>
                    <MenuItem value={12}>DESC</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  // sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  // type="date"
                  size="small"
                  label="Account Number"
                  value={accountNumber}
                  onChange={(event) => setAccountNumber(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Next Due Date"
                  value={nextDueDate}
                  onChange={(event) => setNextDueDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.RECHARGE ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                RECHARGE
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <FormControl sx={{ width: 220 }} size="small">
                  <InputLabel>Select Month</InputLabel>
                  <Select
                    value={selectMonth}
                    label="Select Month"
                    onChange={(event) => setSelectMonth(event.target.value)}
                  >
                    <MenuItem value={1}>JAN</MenuItem>
                    <MenuItem value={2}>FEB</MenuItem>
                    <MenuItem value={3}>MARCH</MenuItem>
                    <MenuItem value={4}>APRIL</MenuItem>
                    <MenuItem value={5}>MAY</MenuItem>
                    <MenuItem value={6}>JUNE</MenuItem>
                    <MenuItem value={7}>JULY</MenuItem>
                    <MenuItem value={8}>AUG</MenuItem>
                    <MenuItem value={9}>SEP</MenuItem>
                    <MenuItem value={10}>OCT</MenuItem>
                    <MenuItem value={11}>NOV</MenuItem>
                    <MenuItem value={12}>DESC</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  // sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{ width: 100 }}
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  // type="date"
                  size="small"
                  label="Mobile Number"
                  value={accountNumber}
                  onChange={(event) => setAccountNumber(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  type="date"
                  size="small"
                  label="Next Due Date"
                  value={nextDueDate}
                  onChange={(event) => setNextDueDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : selectedExpenseType === expense_type.OTHERS ? (
            <Paper
              elevation={8}
              sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mb: "10px",
                }}
              >
                OTHERS
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  flexWrap: { xs: "wrap", md: "nowrap" },
                }}
              >
                {" "}
                <TextField
                  type="date"
                  size="small"
                  label="Paid Date"
                  value={paidDate}
                  onChange={(event) => setPaidDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "200px" }}
                  inputProps={{
                    max: moment().format("YYYY-MM-DD"), // Set the maximum date
                  }}
                />
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                  label="Purpose"
                  variant="outlined"
                  size="small"
                  value={purpose}
                  onChange={(event) => setPurpose(event.target.value)}
                />
                <Button onClick={handleUploadRecipt} size="small">
                  <FileUpload sx={{ mt: "8px" }} />
                  <input
                    type="file"
                    ref={uploadPaymentRecipt}
                    style={{ display: "none" }}
                    onChange={(e) => setUploadReciptFile(e.target.files[0])}
                  />
                </Button>
              </Box>
            </Paper>
          ) : (
            ""
          )}
          {props.editNextDueDate === "edit" ? (
            <>
              <Paper
                elevation={8}
                sx={{ padding: "10px", mt: "10px", bgcolor: "#f5eef8" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    label="Reason"
                    variant="outlined"
                    size="small"
                    value={purpose}
                    sx={{ mt: "5px" }}
                    onChange={(event) => setPurpose(event.target.value)}
                  />
                  <Box>
                    <Typography sx={{ color: "red" }}>
                      Stop Expense :
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        sx={{
                          color: stopSwitch === false ? "#30A2F3" : "black",
                        }}
                      >
                        Disable
                      </Typography>
                      <AntSwitch
                        checked={stopSwitch}
                        onChange={(event) =>
                          setStopSwitch(event.target.checked)
                        }
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography
                        sx={{
                          color: stopSwitch === true ? "#30A2F3" : "black",
                        }}
                      >
                        Enable
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Paper>
            </>
          ) : (
            ""
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={handleCloseVehicleDialog}
        >
          close
        </Button>
        <Button variant="contained" onClick={handleSubmitVehicleDialog}>
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VehicleAddOperatingLevelCostDialog;
