/**
 * @copycenter Copycenter (c) 2024 Bookwater
 * @author Gowtham Prasath
 * @date 08-01-2025
 * @description Component to get the list of PDE Order details
 */

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AutocompleteAddressFields from "../zoneComponents/AutocompleteAddressFields";
import RegionFilters from "./RegionFilters";
import { styles } from "../../Pages/AdminOrders/ordersStyle";
import FilterClose from "../../Images/FilterClose.svg";

import FiberPinIcon from "@mui/icons-material/FiberPin";
import { useState } from "react";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import FilterListIcon from "@mui/icons-material/FilterList";
import BasicSelectField from "../Custom/selectField";
import ObjectSelectField from "../Custom/selectField1";
export default function OrdersFilterData({
  orderType,
  orderTypes,
  orderStatus,
  setOrderStatus,
  orderStatusDetails,
  OrderDetailsAndSummaryApi,
  setOrderType,
  selectedState,
  setFilterhouseCity,
  setSelectedCountry,
  setSelectedState,
  selectedCountry,
  product,
  setProduct,
  setPincode,
  pincode,
  queryParams,
  handleChange,
  products,
  isOpen,
  setIsOpen,
  handelClearFilter,
}) {
  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box
      sx={{
        height: isOpen ? "70vh" : "9rem",
        boxShadow: 4,
        borderRadius: ".5rem",
        position: "relative",
      }}
    >
      <Box sx={styles.filterText(isOpen)} onClick={onToggle}>
        <Box>
          {isOpen && (
            <p
              style={{
                ...styles.filterText,
                marginLeft: ".5rem",
              }}
            >
              Filter Options{" "}
            </p>
          )}
        </Box>
        <IconButton onClick={onToggle} sx={{ size: "small" }}>
          {isOpen && (
            <img
              alt="Bookwater"
              src={FilterClose}
              style={{ height: "1.5rem", width: "1.5rem" }}
            />
          )}
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "sticky",
          top: 24,
          right: 0,
          height: isOpen ? "60vh" : "16rem",
          transition: "width 0.3s ease",
          width: isOpen ? 220 : 40,
          overflow: "hidden",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "0 .5rem .5rem .5rem",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "column",
            },
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {/* Order Type */}
          <Box
            sx={{
              ...styles.foMainBox,
            }}
          >
            <p
              style={{
                ...styles.filterHeader,
              }}
            >
              Order Type
            </p>
            <BasicSelectField
              options={orderTypes}
              selectedoptions={orderType}
              setSelectedOptions={setOrderType}
              helperText={""}
            />
          </Box>
          {/* Order Status */}
          <Box
            sx={{
              ...styles.foMainBox,
            }}
          >
            {" "}
            <p
              style={{
                ...styles.filterHeader,
              }}
            >
              Order Status
            </p>
            <ObjectSelectField
              options={orderStatusDetails}
              selectedoptions={orderStatus}
              setSelectedOptions={setOrderStatus}
              helperText={""}
            />
          </Box>
          {/* products */}
          <Box
            sx={{
              ...styles.foMainBox,
            }}
          >
            <p
              style={{
                ...styles.filterHeader,
              }}
            >
              Products
            </p>
            <Select
              size="small"
              fullWidth
              value={product}
              onChange={(e) => {
                setProduct(e.target.value);
              }}
            >
              {products.map((data, key) => {
                return (
                  <MenuItem value={data.name} key={key}>
                    {data.name} - ({data.capacity} {data.material} )
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box
            sx={{
              ...styles.foMainBox,
            }}
          >
            <p
              style={{
                ...styles.filterHeader,
              }}
            >
              Pincodes
            </p>

            <TextField
              type="number"
              fullWidth
              size="small"
              value={pincode}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setPincode(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <FiberPinIcon
                    sx={{
                      ...styles.searchOrderUserIcon,
                      margincenter: "10px",
                    }}
                  />
                ),
              }}
            />
          </Box>

          <Box
            sx={{
              ...styles.foMainBox,
            }}
          >
            <p
              style={{
                ...styles.filterHeader,
              }}
            >
              Zone Name
            </p>
            <AutocompleteAddressFields
              key_name="zone_name"
              // variant="standard"
              // label="Zone Name"
              value={queryParams.zone_name}
              setValue={(value) => handleChange("zone_name", value)}
            />
          </Box>

          {/* Page Size */}
          <Box
            sx={{
              width: "100%",
              marginBottom:"3rem"
            }}
          >
            <RegionFilters
              selectedState={selectedState}
              setFilterhouseCity={setFilterhouseCity}
              setSelectedCountry={setSelectedCountry}
              setSelectedState={setSelectedState}
              selectedCountry={selectedCountry}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          position: "absolute",
          bottom: 5,
          width: "100%",
          background: "white",
        }}
      >
        {/* Filter Search */}
        <Button
          variant="outlined"
          size="small"
          sx={{
            borderRadius: "10px",
          }}
          onClick={() => handelClearFilter()}
        >
          Clear{" "}
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{
            borderRadius: "10px",
          }}
          onClick={() => OrderDetailsAndSummaryApi()}
        >
          Filter
        </Button>
      </Box>
    </Box>
  );
}
