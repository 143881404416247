import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  Grid,
} from "@mui/material";
import PrimaryButton from "../Buttons/primaryButton";
import { appColors } from "../Assests/AppTheme/appThemeConst";
import FileUploadButton from "../Buttons/fileUploadButton";
import { DownloadRounded } from "@mui/icons-material";
import { INV_TAX_TYPES, manufactureOrder } from "../AppMeta/appMetaConfig";
import { useEffect, useState } from "react";

export default function ManuFactureOrderEdit({
  open,
  setOpen,
  componentData,
  setComponentData,
  handelUpdateOrder,
  selectedData,
  handelUploadPo,
  handelApprovePo,
  handelCompleteOrder,
  handelUploadPr,
  handelApprovePr,
  handelDownloadPr,
  handelDownloadPo,
  setValidateOrder,
  validateOrder,
  handelValidateOrder,
  handelCancelOrder,
  handelDownloadDraftPo,
}) {
  const status = [manufactureOrder.CANCELLED, manufactureOrder.COMPLETED];

  // Handle Quantity Change
  const [taxType, setTaxType] = useState();
  const handleQuantityChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      // Allow only numeric input
      const updatedData = [...componentData];
      updatedData[index].quantity = value;
      setComponentData(updatedData);
      setValidateOrder(true);
    }
  };

  // Handle price Change
  const handlePriceChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      // Allow only numeric input
      const updatedData = [...componentData];
      updatedData[index].price = value;
      setComponentData(updatedData);
      setValidateOrder(true);
    }
  };
  //function to handel close dialog
  const handelClose = () => {
    setOpen(false);
    setComponentData({});
  };

  console.log(componentData);
  useEffect(() => {
    if (selectedData) {
      setTaxType(selectedData?.details?.tax_type || INV_TAX_TYPES.STATE);
    } else {
      setTaxType(INV_TAX_TYPES.CENTRAL);
    }
  }, [selectedData]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <Box sx={cardStyles.headerBox}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Order Details
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: appColors.blueColor,
                fontWeight: "500",
              }}
            >
              {selectedData?.order_id || "N/A"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{}}>
            <Box sx={cardStyles.headerBox}>
              <Typography sx={cardStyles.typoHeader}>Products</Typography>
              <Box>
                {selectedData?.po_file && (
                  <Button onClick={handelDownloadPo}>
                    <DownloadRounded /> PO
                  </Button>
                )}
                {selectedData?.payment_receipt && (
                  <Button>
                    <DownloadRounded onClick={handelDownloadPr} /> PR
                  </Button>
                )}
              </Box>
            </Box>
            <Card sx={{ width: "100%", marginBottom: 1, boxShadow: 1 }}>
              {componentData?.map((item, index) => (
                <>
                  <CardContent key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography sx={cardStyles.typoHeader}>
                          <span style={{ fontWeight: 500 }}>
                            {`${item.capacity}/${item.material}`} :
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Quantity"
                          variant="outlined"
                          size="small"
                          disabled={
                            status.includes(selectedData?.order_status) ||
                            !selectedData?.buttons?.edit_order
                          }
                          value={item.quantity}
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                          error={item.quantity === ""}
                          helperText={
                            item.quantity === "" ? "Quantity is required" : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Unit Price"
                          variant="outlined"
                          size="small"
                          value={item.price}
                          disabled={
                            status.includes(selectedData?.order_status) ||
                            !selectedData?.buttons?.edit_order
                          }
                          onChange={(e) =>
                            handlePriceChange(index, e.target.value)
                          }
                          error={item.price === ""}
                          helperText={
                            item.price === "" ? "Price is required" : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {INV_TAX_TYPES.CENTRAL ==
                        selectedData?.details?.tax_type ? (
                          <Box sx={cardStyles.boxContainer}>
                            <Typography sx={cardStyles.typoHeader}>
                              Price Total :{" "}
                              <span
                                style={cardStyles.spanText}
                              >{`₹${item.total}`}</span>
                            </Typography>
                            <Typography sx={cardStyles.typoHeader}>
                              IGST :{" "}
                              <span style={cardStyles.spanText}>{`₹${
                                Number(item.cgst_amt) + Number(item.sgst_amt)
                              }(${
                                Number(item.sgst_perc) + Number(item.cgst_perc)
                              }%)`}</span>
                            </Typography>

                            <Typography sx={cardStyles.typoHeader}>
                              Total :{" "}
                              <span
                                style={cardStyles.spanText}
                              >{`₹${item.grand_total}`}</span>
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={cardStyles.boxContainer}>
                            <Typography sx={cardStyles.typoHeader}>
                              Price Total :{" "}
                              <span
                                style={cardStyles.spanText}
                              >{`₹${item.total}`}</span>
                            </Typography>
                            <Typography sx={cardStyles.typoHeader}>
                              SGST :{" "}
                              <span
                                style={cardStyles.spanText}
                              >{`₹${item.sgst_amt}(${item.sgst_perc}%)`}</span>
                            </Typography>

                            <Typography sx={cardStyles.typoHeader}>
                              CGST :{" "}
                              <span
                                style={cardStyles.spanText}
                              >{`₹${item.cgst_amt}(${item.cgst_perc}%)`}</span>
                            </Typography>
                            <Typography sx={cardStyles.typoHeader}>
                              Total :{" "}
                              <span
                                style={cardStyles.spanText}
                              >{`₹${item.grand_total}`}</span>
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                  {componentData?.length > 1 && (
                    <div style={{ borderBottom: "2px dashed #829808" }}></div>
                  )}
                </>
              ))}
            </Card>
          </Box>
        </DialogContent>
        <DialogActions sx={cardStyles.boxContainer1}>
          <PrimaryButton
            handleButtonClick={handelClose}
            btnName="Close"
            color="red"
          />
          {selectedData?.buttons?.upload_po && (
            <FileUploadButton
              buttonName="Upload Po"
              styles={{ background: appColors.blueColor }}
              handelFileUpload={handelUploadPo}
            />
          )}
          {selectedData?.buttons?.upload_po && (
            <PrimaryButton
              btnName="Download Po"
              color={{ background: appColors.blueColor }}
              handleButtonClick={handelDownloadDraftPo}
            />
          )}
          {selectedData?.buttons?.approve_po && (
            <PrimaryButton
              handleButtonClick={handelApprovePo}
              btnName="Approve Po"
              color="green"
            />
          )}
          {selectedData?.buttons?.complete_order && (
            <PrimaryButton
              handleButtonClick={handelCompleteOrder}
              btnName="Complete Order"
              color="green"
            />
          )}
          {selectedData?.buttons?.upload_pr && (
            <FileUploadButton
              buttonName="Upload PR"
              styles={{ background: appColors.blueColor }}
              handelFileUpload={handelUploadPr}
            />
          )}
          {selectedData?.buttons?.approve_pr && (
            <PrimaryButton
              handleButtonClick={handelApprovePr}
              btnName="Approve PR"
              color="green"
            />
          )}

          {selectedData?.buttons?.cancel_order && (
            <PrimaryButton
              handleButtonClick={handelCancelOrder}
              btnName="Cancel"
              color="red"
            />
          )}

          {!status.includes(selectedData?.order_status) &&
          !validateOrder &&
          selectedData?.buttons?.edit_order ? (
            <PrimaryButton
              handleButtonClick={handelUpdateOrder}
              btnName="Update"
              color={appColors.blueColor}
            />
          ) : (
            ""
          )}
          {!status.includes(selectedData?.order_status) &&
          validateOrder &&
          selectedData?.buttons?.edit_order ? (
            <PrimaryButton
              handleButtonClick={handelValidateOrder}
              btnName="Validate"
              color={appColors.blueColor}
            />
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
const cardStyles = {
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
    flexWrap: "wrap",
  },
  boxContainer1: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: 2,
    flexWrap: "wrap",
  },
  mainBox: {
    display: "flex",
    flexDirection: "column",
  },
  manuFactureDetails: {
    fontSize: "1.2rem",
    fontWeight: 500,
    textAlign: "start",
  },
  typoHeader: { fontSize: ".9rem", fontWeight: "Bold" },
  spanText: { fontSize: ".9rem", fontWeight: "500" },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    borderRadius: "4px",
  },
};
