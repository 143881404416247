/**
 * @author Gowtham Prasath
 * @description Book Now
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.1
 * @Date 20-01-2024
 */

import { Button, Switch } from "@mui/material";
import Paper from "@mui/material/Paper";
import CustomConsole from "../../CustomConsole";
import React from "react";
import { Box } from "@mui/system";
import CreateRoles from "./CreateRoles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetAdminRoles } from "../Redux/Actions/superAdminAction";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function AdminRolesTable({ roles, departmentList }) {
  const dispatch = useDispatch();
  const superAdminUpdates = useSelector((state) => state.superAdminUpdates);
  const [openAddNewDlg, setOpenAddNewDlg] = React.useState(false);
  const [addRoleFlag, setAddRoleFlag] = React.useState(false);
  const [roleName, setRoleName] = React.useState("");
  const [editRole, setEditRole] = React.useState(false);
  const [roleId, setRoleId] = React.useState("");
  const [editRoleFlag, setEditRoleFlag] = React.useState(false);

  React.useEffect(() => {
    dispatch(GetAdminRoles());
  }, [dispatch]);

  React.useEffect(() => {
    if (addRoleFlag && !superAdminUpdates.addingError) {
      toast.success(superAdminUpdates.msg, {
        position: "bottom-right",
        autoClose: 2000,
      });
      dispatch(GetAdminRoles());
      setOpenAddNewDlg(false);
      setAddRoleFlag(false);
    }
    if (editRoleFlag && !superAdminUpdates.error) {
      toast.success(superAdminUpdates.msg, {
        position: "bottom-right",
        autoClose: 2000,
      });
      dispatch(GetAdminRoles());
      setOpenAddNewDlg(false);
      setEditRoleFlag(false);
    }
  }, [superAdminUpdates, addRoleFlag, editRoleFlag, dispatch]);

  const addingNewRoles = () => {
    // setOpenAddNewDlg(true);
    // setRoleName("");
    // setEditRole(false);
    toast.info("Adding New Role Feature is in Development Stage!!", {
      position: "bottom-right",
      autoClose: 2000,
    });
  };

  const editRoles = (name, id) => {
    toast.info("Editing the Admin Roles is in Development Stage!!", {
      position: "bottom-right",
      autoClose: 2000,
    });
    // setOpenAddNewDlg(true);
    // setEditRole(true);
    // setRoleName(name);
    // setRoleId(id);
  };

  const disableEnableRole = (bool, name, id) => {
    toast.info("Enable/Disable the Admin Roles is in Development Stage!!", {
      position: "bottom-right",
      autoClose: 2000,
    });
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      flex: 0.5,
      valueGetter: (params) => {
        const rowIds = params.api.getSortedRowIds();
        return rowIds.indexOf(params.id) + 1;
      },
    },
    { field: "role_name", headerName: "Role Name", flex: 1 },
    {
      field: "is_disabled",
      headerName: "Change Status",
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={!params.is_disabled}
          onChange={() =>
            disableEnableRole(
              params.is_disabled,
              params.role_name,
              params.role_id
            )
          }
          color="success"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{ marginRight: "5px" }}
          size="small"
          variant="contained"
          onClick={() => editRoles(params.role_name, params.role_id)}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Button
          sx={{ float: "right", margin: "5px 5px 5px 0px" }}
          variant="contained"
          onClick={addingNewRoles}
        >
          Add New Role
        </Button>
      </Box>
      <Paper>
        <DataGrid
          rows={roles}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[25, 30, 40, 50, 100]}
          getRowId={(row) => row.role_id}
          disableSelectionOnClick
          autoHeight
          components={{
            Toolbar: GridToolbar,
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "#f4f4f4",
            },
            "& .MuiDataGrid-footerContainer": {
              position: "sticky",
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "#f4f4f4",
            },
          }}
        />
      </Paper>
      {openAddNewDlg && (
        <CreateRoles
          openAddNewDlg={openAddNewDlg}
          setOpenAddNewDlg={setOpenAddNewDlg}
          setAddRoleFlag={setAddRoleFlag}
          roleName={roleName}
          setRoleName={setRoleName}
          editRole={editRole}
          roleId={roleId}
          setEditRoleFlag={setEditRoleFlag}
          departmentList={departmentList}
        />
      )}
    </div>
  );
}

export default AdminRolesTable;
