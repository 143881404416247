/**
 * @author Gowtham Prasath
 * @description Place Emppty Can orders page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PlaceOrderEmptyCans } from "../../Pages/Redux/Actions/adminLineDetailsAction";
import {
  GetAvailableProducts,
  GetBookwaterStock,
  GetListofCrates,
} from "../../Pages/Redux/Actions/SKUProductDetailsAction";
import { InputAdornment } from "@mui/material";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import CachedIcon from "@mui/icons-material/Cached";
import { WfsEmptyCanOrdersSku } from "../../Pages/Redux/Actions/skuWfsDetailsAction";
import CustomConsole from "../../CustomConsole";
export default function WfsAdminEmptyCanOrderDialog({ open, onClose, userId }) {
  const dispatch = useDispatch();
  const wfsLineDetails = useSelector((state) => state.adminWfsLineData);
  const [productName, setProductName] = React.useState("");
  const [productId, setProductId] = React.useState("");
  const [bookwaterStock, setBookwaterStock] = React.useState();
  const [crateName, setCrateName] = React.useState("");
  const [orderParams, setOrderParams] = React.useState({
    container_material: "",
    container_capacity: "",
    user_id: userId,
    order_qty: 0,
    return_qty: 0,
    crates: [],
  });
  const availableStock = bookwaterStock?.empty - bookwaterStock?.ongoing_qty;
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const crateDetails = skuContainerDetails.cateDetails;

  //useEffect for getting list available crates
  React.useEffect(() => {
    dispatch(
      GetListofCrates({
        activeStatus: true,
        capacity: "ASC",
        sort_by_name: "ASC",
        sort_by_price: "ASC",
        sort_by_created_at: "ASC",
      })
    );
    dispatch(GetAvailableProducts());
  }, []);

  //function for handling selected product
  const handleSelectedProduct = (data) => {
    setProductId(data.target.value);
    CustomConsole(skuContainerDetails.getAvailableProducts);
    const product = data.target.value;
    CustomConsole(product);
    let duplicateElement = skuContainerDetails.getAvailableProducts.find(
      (data) => data.product_details.id == product
    );
    CustomConsole(duplicateElement);
    if (Object.keys(duplicateElement).length > 0) {
      setOrderParams((prev) => ({
        ...prev,
        container_material: duplicateElement.product_details.material,
        container_capacity: duplicateElement.product_details.capacity,
      }));
      setProductName(duplicateElement.product_details.name);
    }
  };

  CustomConsole(orderParams);

  //function for handling orderqty minus action
  const handelMinusQuantity = () => {
    if (orderParams.order_qty >= 1) {
      setOrderParams((prev) => ({
        ...prev,
        order_qty: orderParams.order_qty - 1,
      }));
    }
  };
  //function for handling orderqty plus action
  const handelAddQuantity = () => {
    if (parseInt(orderParams.order_qty) + 1 > (availableStock || 0)) {
      return toast.error(
        "Please Validate the stock before Updating the Quantity!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
    setOrderParams((prev) => ({
      ...prev,
      order_qty: parseInt(orderParams.order_qty) + 1,
    }));
  };

  //function for handling orderqty plus action
  const handelQuantityChange = (event) => {
    // Extract the input value as a number
    const inputValue = parseInt(event.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        order_qty: inputValue,
      }));
    }
  };

  //function for deleting product detiails
  const deleteProductDetails = (event) => {
    setOrderParams((prev) => ({
      ...prev,
      container_material: "",
      container_capacity: "",
    }));
    setProductId("");
  };

  //function for handling crate adding
  const handelSelectedCrate = () => {
    let crateData = crateDetails?.find((data) => data.name === crateName);
    let duplicateElement = orderParams?.crates.filter(
      (data) => data.name === crateName
    );
    CustomConsole(crateData);
    if (
      Object.keys(crateData !== undefined && crateData !== null && crateData)
        .length > 0 &&
      duplicateElement?.length === 0
    ) {
      setOrderParams((prev) => ({
        ...prev,
        crates: [
          ...prev.crates,
          { name: crateData.name, order_qty: 0, capacity: crateData.capacity },
        ],
      }));
    }
  };

  //function for adding crate name
  const handelChangeSelectedCrate = (e) => {
    setCrateName(e.target.value);
  };
  CustomConsole(orderParams);

  //function for handling crate adding
  const handelAddCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) =>
        crate.name === data.name
          ? { ...crate, order_qty: parseInt(crate.order_qty) + 1 }
          : crate
      ),
    }));
  };
  //function for handling crate adding
  const handelMinusCrateQuantity = (data) => {
    setOrderParams((prev) => ({
      ...prev,
      crates: prev.crates.map((crate) => {
        if (crate.name === data.name && crate.order_qty > 0) {
          // Update the order_qty for the matching crate
          return { ...crate, order_qty: crate.order_qty - 1 };
        }
        // Return the original crate object if it doesn't match the condition
        return crate;
      }),
    }));
  };
  //function for handling crate adding
  const handelCrateQuantityChange = (data, e) => {
    // Extract the input value as a number
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, order_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };

  //const delete crate details
  const deleteCrateDetails = (index) => {
    setOrderParams((prev) => {
      const newCrates = [...prev.crates];
      newCrates.splice(index, 1);
      return { ...prev, crates: newCrates };
    });
  };

  //function for placing wfs empty can orders
  const handelOrderSubmit = () => {
    const quantityCheck = orderParams.crates.some(
      (data) => data.order_qty === 0
    );
    if (orderParams.order_qty > 0 && !quantityCheck) {
      dispatch(WfsEmptyCanOrdersSku(orderParams));
      onClose();
    } else {
      toast.error("Please add order Quantity to Place order", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  //change Order quantity in text field
  const handleChangeorderQty = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (numericValue > (availableStock || 0)) {
      return toast.error(
        "Please Validate the stock before Updating the Quantity!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
    setOrderParams((prev) => ({
      ...prev,
      order_qty: event.target.value,
    }));
  };
  const getBookwaterStock = () => {
    if (productId) {
      dispatch(GetBookwaterStock({ container_id: productId })).then((data) => {
        setBookwaterStock(data?.data?.[0] || null);
      });
    }
  };
  const handleChangeCrateQty = (data, event) => {
    CustomConsole(data);
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    event.target.value = numericValue;
    if (!isNaN(inputValue) && inputValue >= 0) {
      setOrderParams((prev) => ({
        ...prev,
        crates: prev.crates.map((crate) => {
          if (crate.name === data.name) {
            return { ...crate, order_qty: inputValue };
          }
          return crate;
        }),
      }));
    }
  };

  React.useEffect(() => {
    getBookwaterStock();
  }, [productId]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignContent="center"
            >
              <Grid item md={12} xs={12}>
                <Typography
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  Select Product
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    sx={{
                      width: { sm: "100%", xs: "100%" },
                      marginTop: ".6rem",
                    }}
                    size="small"
                    value={productId}
                    onChange={handleSelectedProduct}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" disabled>
                      Select Capacity
                    </option>
                    {skuContainerDetails.getAvailableProducts.map((option) => (
                      <option
                        key={option.product_details.id}
                        value={option.product_details.id}
                      >
                        {option.product_details.name}/Material:
                        {option.product_details.material}/Capacity:
                        {option.product_details.capacity}
                      </option>
                    ))}
                  </TextField>
                </Box>
                {orderParams.container_capacity !== "" ? (
                  <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          {productName}/{orderParams.container_capacity}/
                          {orderParams.container_material}
                        </Typography>
                        <DeleteIcon
                          color="error"
                          onClick={() => deleteProductDetails()}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                          gap: "20px",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography>Required</Typography>
                        <Typography>
                          <b> Available Stock</b> : {availableStock || 0}{" "}
                          <CachedIcon
                            // sx={{ p: 2 }}
                            onClick={() => getBookwaterStock()}
                          />
                        </Typography>
                        <OrderButtonSku
                          handelAddQuantity={handelAddQuantity}
                          handelMinusQuantity={handelMinusQuantity}
                          handelQuantityChange={handleChangeorderQty}
                          inputValue={orderParams.order_qty}
                        />
                        {/* <TextField
                          size="small"
                          label="Requried"
                          sx={{ width: "200px" }}
                          value={orderParams.order_qty}
                          InputProps={{
                            maxLength: 10, // Optional: You can set the maximum length if needed
                            onInput: handleChangeorderQty, // Attach the input event handler
                          }}
                        /> */}
                      </Box>
                    </CardContent>
                    <Box
                      sx={{
                        width: "100%",
                        bgcolor: "#F0FFFF",
                        height: "30px",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography sx={{ marginLeft: "1rem" }}>
                        Order Qty:{orderParams.order_qty}
                      </Typography>
                    </Box>
                  </Card>
                ) : null}
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  Select Crate
                </Typography>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  sx={{
                    width: { sm: "100%", xs: "100%" },
                    marginTop: ".6rem",
                  }}
                  size="small"
                  value={crateName}
                  onChange={handelChangeSelectedCrate}
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={handelSelectedCrate}
                        >
                          Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                >
                  <option>Select Crate</option>
                  {crateDetails.map((option) => (
                    <option key={option.id} value={option.name}>
                      {option.name}/Capacity:{option.capacity}
                    </option>
                  ))}
                </TextField>
                {orderParams.crates.length > 0 ? (
                  <>
                    {orderParams.crates.map((data, index) => (
                      <Card sx={{ marginTop: "1rem", boxShadow: 3 }}>
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>
                              {data.name}/{data.capacity}
                            </Typography>
                            <DeleteIcon
                              color="error"
                              onClick={() => deleteCrateDetails(index)}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexDirection: "column",
                              gap: "20px",
                              marginTop: "1rem",
                            }}
                          >
                            <Typography>Required&nbsp;</Typography>
                            <OrderButtonSku
                              handelAddQuantity={() =>
                                handelAddCrateQuantity(data)
                              }
                              handelMinusQuantity={() =>
                                handelMinusCrateQuantity(data)
                              }
                              handelQuantityChange={(event) =>
                                handleChangeCrateQty(data, event)
                              }
                              inputValue={data.order_qty}
                            />
                            {/* <TextField
                              size="small"
                              sx={{ width: "200px" }}
                              label="Requried"
                              value={data.order_qty}
                              InputProps={{
                                maxLength: 10, // Optional: You can set the maximum length if needed
                                onInput: (event) =>
                                  handleChangeCrateQty(data, event), // Attach the input event handler
                              }}
                            /> */}
                          </Box>
                        </CardContent>
                        <Box
                          sx={{
                            width: "100%",
                            bgcolor: "#F0FFFF",
                            height: "30px",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography sx={{ marginLeft: "1rem" }}>
                            Order Qty:{data.order_qty}
                          </Typography>
                        </Box>
                      </Card>
                    ))}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
        <Button size="small" variant="contained" onClick={handelOrderSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
