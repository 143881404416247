/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Prasath
 */
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function BasicSelectField({
  options,
  selectedoptions,
  setSelectedOptions,
  helperText,
}) {
  const handleChange = (e) => {
    setSelectedOptions(e.target.value);
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          displayEmpty
          fullWidth
          value={selectedoptions}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
size="small"
        >
          {options?.map((data) => (
            <MenuItem value={data}>{data}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>{helperText}</FormHelperText>
    </Box>
  );
}
