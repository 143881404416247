import { appRegex } from "./appMetaConfig";

export const textFieldConfig = {
  // dialog text field
  addManufacture: [
    {
      name: "manufacturer_name",
      label: "Manufacturer Name",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "branch",
      label: "Branch",
      type: "text",
      isRequired: true,
      isHintRequired: false,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "owner_name",
      label: "Owner Name",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "owner_email",
      label: "Owner Email",
      type: "email",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.emailRegEx,
      textFieldType: "text",
    },
    {
      name: "owner_phone",
      label: "Owner Phone",
      type: "number",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.phoneRegEx,
      textFieldType: "text",
    },
    {
      name: "address_line1",
      label: "Door No & Street",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength1,
      textFieldType: "text",
    },
    {
      name: "address_line2",
      label: "Building & Block",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "address_line3",
      label: "Area",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "state",
      label: "State",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "pincode",
      label: "Pincode",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.pinCodeRegEx,
      textFieldType: "text",
    },
    {
      name: "state_code",
      label: "State Code",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength2,
      textFieldType: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "district",
      label: "District",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "country",
      label: "Country",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "gstin",
      label: "Gst In",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.gstNo,
      textFieldType: "text",
    },
  ],
  addRawMaterial: [
    {
      name: "name",
      label: "Name",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "units",
      label: "Units",
      type: "text",
      isRequired: true,
      isHintRequired: false,
      regex: appRegex.nameRegexLength2,
      textFieldType: "text",
    },
    {
      name: "material",
      label: "Material",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "empty_cgst_perc",
      label: "CGST %",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength1,
      textFieldType: "text",
    },
    {
      name: "empty_sgst_perc",
      label: "SGST %",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength1,
      textFieldType: "text",
    },
    {
      name: "empty_hsn_code",
      label: "HSN Code",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },
    {
      name: "status",
    },
  ],
  addPhysicalGiftCard: [
    {
      name: "batchName",
      label: "Batch Name",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength3,
      textFieldType: "text",
    },

    {
      name: "amount_perGiftCard",
      label: "Amount Per Card",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.nameRegexLength1,
      textFieldType: "text",
    },
    {
      name: "numberOfGiftCards",
      label: "Number Of Gift Cards",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.numberRegEx,
      textFieldType: "text",
    },
    {
      name: "assignedTo",
      label: "Assignee Phone",
      type: "number",
      isRequired: false,
      isHintRequired: true,
      regex: appRegex.phoneRegEx,
      textFieldType: "text",
    },
    {
      name: "isPasswordRequired",
      label: "Password Required to redeem",
      type: "radio",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      // isRequired: false,
      // isHintRequired: true,
      regex: appRegex.phoneRegEx,
      textFieldType: "radio",
    },
    {
      name: "redeemThrough",
      label: "Redeem Through",
      type: "radio",
      options: [
        { label: "WALLET", value: "WALLET" },
        { label: "WATER DROPS", value: "WATER_DROPS" },
      ],
      // isRequired: false,
      // isHintRequired: true,
      regex: appRegex.phoneRegEx,
      textFieldType: "radio",
    },
  ],
  createGiftCardSettlement: [
    {
      name: "assignedTo",
      label: "Sales Person Phone",
      type: "text",
      disabled: true,
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.phoneRegEx,
      textFieldType: "text",
    },

    {
      name: "paymentReceived",
      label: "Amount Settled",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.numberRegEx,
      textFieldType: "text",
    },
    {
      name: "numberOfGiftCards_sold",
      label: "Total Sold",
      type: "text",
      isRequired: true,
      isHintRequired: true,
      regex: appRegex.numberRegEx,
      textFieldType: "text",
    },
  ],
};
