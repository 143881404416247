import cssObject from "../../Components/AppMeta/appStyles";

//Style of the component
export const styles = {
  // Searc Order -> Filter Options
  filterText: (isOpen) => ({
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "flex-start",
    display: "flex",
    alignItems: isOpen ? "flex-start" : "flex-end",
    justifyContent: isOpen ? "space-between" : "flex-start",
    color: cssObject.color.darkGrayText,
  }),
  foMainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  foIpLabel: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  filterHeader: {
    fontSize: ".9rem",
    fontWeight: "bold",
    textAlign: "flex-start",
    color: cssObject.color.grayTextColor,
    margin: "5px",
  },
  foSelect: { fontSize: "14px", fontWeight: "bold", width: "140px" },

  instaBgImage: "linear-gradient( #F6E48B, #E7F887)",
  regularBgImage: "linear-gradient( #DDFFC9, #FFEFC9)",
  postpaidBgImage: "linear-gradient( #D8FFF8, #F6E5FF)",
  subscriptionBgImage: "linear-gradient( #FFE5E9, #E6F4EB)",

  //Order Summary Design
  osPaper: {
    padding: "10px",
    margin: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
  },
  summeryBox: {
    padding: "10px",
    margin: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  osBox: {
    padding: "10px",
  },
  osCard: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    boxShadow: 5,
  },
  osCardHeadText: {
    fontSize: ".9rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  osTextBox: { display: "flex", flexDirection: "row", gap: "10px" },
  osTextHead: {
    fontSize: ".8rem",
    fontWeight: "bold",
    color: "gray",
  },
  osTextAnswer: {
    fontSize: ".8rem",
    fontWeight: "bold",
    color: "black",
  },

  filterDataBoxMain: {
    display: "flex",
    gap: { md: "15px", xs: "10px" },
    padding: "10px",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  searchOrderUserIcon: {
    color: "blue",
    fontSize: "25px",
    "&:hover": {
      color: "black",
    },
  },
  reportDownloadIcon: {
    color: "brown",
    fontSize: "35px",
    "&:hover": {
      color: "green",
    },
  },
  searchDateIcon: {
    color: "blue",
    fontSize: "2rem",
    "&:hover": {
      color: "black",
    },
  },
  paperCommon: {
    padding: "10px",
    margin: "10px",
    width: "100%",
    boxShadow: 5,
    borderRadius: ".5rem",
  },
};
