import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FirstPage, LastPage } from "@mui/icons-material";
import { CSVLink } from "react-csv";
export default function CoustomPagiation({
  setOpenPincodeFilter,
  csvLink,
  setPageNumber,
  handlePageSizeChange,
  pageNumber,
  toCSV,
  handelFilterDialog,
  pageSize,
  data,
  downLoad,
  filterButton,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
        overflowY: "auto",
        scrollbarWidth: "thin",
        width: "100%",
      }}
    >
      {" "}
      {filterButton && (
        <Button
          onClick={() => handelFilterDialog()}
          size="small"
          startIcon={<FilterListIcon />}
        >
          Filter
        </Button>
      )}
      <Box
        sx={{
          maxWidth: "120px",
          maxHeight: "10px",
        }}
      >
        <FormControl fullWidth>
          <NativeSelect
            value={pageSize}
            size="small"
            onChange={handlePageSizeChange}
            inputProps={{
              name: "pageSize",
              // id: "uncontrolled-native",
            }}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </NativeSelect>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            if (pageNumber - 1 >= 0) {
              setPageNumber(() => pageNumber - 1);
            }
          }}
        >
          <FirstPage sx={{ color: pageNumber - 1 >= 0 ? "blue" : "gray" }} />
        </IconButton>
        <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
          {pageNumber + 1}
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            if (pageSize === data?.length) {
              setPageNumber(() => pageNumber + 1);
            }
          }}
        >
          <LastPage
            sx={{
              color: pageSize > data?.length ? "gray" : "blue",
            }}
          />
        </IconButton>
      </Box>
      <Box>
        {csvLink && (
          <CSVLink {...csvLink}>
            {" "}
            <DownloadForOfflineIcon
              sx={{ fontSize: 25 }}
              onClick={() => toCSV(data)}
            />
          </CSVLink>
        )}
      </Box>
    </Box>
  );
}
