import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../styles/PrintMFTPo.css";
const PoFileDownload = React.forwardRef(({ data }) => {
  const componentRef = useRef();
  const printFn = useReactToPrint({
    content: () => componentRef?.current,
  });
  const isDraft = !data.order_id;
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = printStyles;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  useEffect(() => {
    if (data) {
      printFn();
    }
  }, [data]);
  return (
    <div ref={componentRef} style={styles.container} className="container">
      {isDraft && (
        <div style={styles.watermark}>
          <p>Draft Order</p>
        </div>
      )}

      {/* Header Section */}
      <div style={styles.header}>
        <h2 style={styles.headerTitle}>Purchase Order</h2>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <img
            src="https://www.bookwater.com/wp-content/uploads/2024/01/bw_logo_color_b_black_text_with_r.png"
            alt="BookWater Logo"
            style={styles.logo}
          />
        </div>
        <div
          style={{
            ...styles.infoSection,
            float: "right",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p>
            <strong>Voucher No:</strong> {data.order_id}
          </p>
          <p>
            <strong>Date:</strong> {new Date(data.created_at).toLocaleString()}
          </p>
        </div>
      </div>
      {/* Info Section */}
      <div style={styles.infoSection}>
        <div>
          <div style={styles.addressContainer}>
            <address style={styles.address}>
              <p>
                <b>Recipient:</b>
              </p>
              <p>
                {[
                  data?.addresses?.billing_address?.supplier_profile
                    ?.company_name,
                  data?.addresses?.billing_address?.supplier_profile
                    ?.address_line1,
                  data?.addresses?.billing_address?.supplier_profile
                    ?.address_line2,
                  data?.addresses?.billing_address?.supplier_profile
                    ?.address_line3,
                  data?.addresses?.billing_address?.supplier_profile?.city,
                  data?.addresses?.billing_address?.supplier_profile?.state,
                  `StateCode - ${
                    data?.addresses?.billing_address?.supplier_profile
                      ?.state_code || " - "
                  }`,
                  `Pincode - ${data?.addresses?.billing_address?.supplier_profile?.pincode}`,
                ]
                  ?.map((item) => item)
                  .join(", ")}
              </p>

              <p>
                <b>GSTIN/UIN</b>:{" "}
                {data?.addresses?.billing_address?.supplier_profile?.gst}
              </p>
            </address>
            <address style={styles.address}>
              <p>
                <b>Vendor:</b>
              </p>
              <p>
                {" "}
                {[
                  data.mft_details.manufacturer_name,
                  data.mft_details.company_profile?.address?.address_line1,
                  data.mft_details.company_profile?.address?.address_line2,
                  data.mft_details.company_profile?.address?.city,
                  data.mft_details.company_profile?.address?.state_name,
                  `StateCode - ${
                    data.mft_details.company_profile?.address?.state_code ||
                    " - "
                  }`,
                  `Pincode - ${data.mft_details.company_profile?.address?.pincode}`,
                ]
                  ?.map((item) => item)
                  .join(", ")}
              </p>
              <p>
                <b>GSTIN/UIN</b>: {data.mft_details.tax_details?.gstin}
              </p>
            </address>
            <address style={styles.address}>
              <p>
                <b>Delivery Address:</b>
              </p>
              <p>
                {[
                  data?.addresses?.delivery_address?.contact_name,
                  data?.addresses?.delivery_address?.address_line1,
                  data?.addresses?.delivery_address?.address_line2,
                  data?.addresses?.delivery_address?.address_line3,
                  data?.addresses?.delivery_address?.city,
                  data?.addresses?.delivery_address?.state,
                  `Pincode - ${data?.addresses?.delivery_address?.pincode}`,
                ]
                  ?.map((item) => item)
                  .join(", ")}
              </p>
            </address>
          </div>
        </div>
      </div>

      {/* Products Table */}
      <table style={styles.table} className="content">
        <thead>
          <tr style={styles.tableHeader}>
            <th style={styles.tableCell} rowspan="2">
              Sl. No
            </th>
            <th style={styles.tableCell} rowspan="2">
              Product
            </th>
            <th style={styles.tableCell} rowspan="2">
              HSN / SAC
            </th>
            <th style={styles.tableCell} rowspan="2">
              Rate
            </th>
            <th style={styles.tableCell} rowspan="2">
              Qty
            </th>

            {data.addresses?.is_same_state ? (
              <>
                <th style={styles.tableCell} colspan="2">
                  CGST
                </th>
                <th style={styles.tableCell} colspan="2">
                  SGST
                </th>
              </>
            ) : (
              <>
                {" "}
                <th style={styles.tableCell} colspan="2">
                  IGST
                </th>
              </>
            )}

            <th style={styles.tableCell} rowspan="2">
              total
            </th>
          </tr>
          <tr style={styles.tableHeader}>
            {data.addresses?.is_same_state ? (
              <>
                <th style={styles.tableCell}>%</th>
                <th style={styles.tableCell}>₹</th>
                <th style={styles.tableCell}>%</th>
                <th style={styles.tableCell}>₹</th>
              </>
            ) : (
              <>
                <th style={styles.tableCell}>%</th>
                <th style={styles.tableCell}>₹</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data.products?.map((item, index) => (
            <tr key={item.id}>
              <td style={styles.tableCell}>{index + 1}</td>
              <td style={styles.tableCell}>{item.details?.name}</td>
              <td style={styles.tableCell}>{item.hsn_code}</td>
              <td style={styles.tableCell}>{item.price}</td>
              <td style={styles.tableCell}>{item.quantity}</td>

              {data.addresses?.is_same_state ? (
                <>
                  <td style={styles.tableCell}>{item.sgst_perc}</td>
                  <td style={styles.tableCell}>{item.cgst_amt}</td>
                  <td style={styles.tableCell}>{item.sgst_perc}</td>
                  <td style={styles.tableCell}>{item.sgst_amt}</td>
                </>
              ) : (
                <>
                  <td style={styles.tableCell}>{item.sgst_perc}</td>
                  <td style={styles.tableCell}>{item.sgst_amt}</td>
                </>
              )}

              <td style={styles.tableCell}>{item.grand_total || 0}</td>
            </tr>
          ))}
          <tr>
            <td
              style={styles.tableCell}
              colSpan={data.addresses?.is_same_state ? "9" : "7"}
            >
              Grand Total:
            </td>
            <td style={{ ...styles.tableCell, ...styles.grandTotal }}>
              {data.order_summary?.order_total || 0}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Amount in Words */}
      <div>
        <p>
          <strong>Amount in Words:</strong> {data.letteralAmount}
        </p>
      </div>

      {/* Declaration Section */}
      {data.declaration ? (
        <div style={styles.declaration}>
          <p>
            <strong>Declaration:</strong>
          </p>
          <div
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: data.declaration || "" }}
          ></div>
        </div>
      ) : (
        <></>
      )}

      {/* Signature Section */}
      <div style={styles.signatureSection}>
        <p>
          <strong>For BookWater Tech Private Limited</strong>
        </p>
        <p style={styles.signature}>Authorized Signatory</p>
      </div>

      {/* Footer */}
      <div style={styles.footer} className="footer">
        <p>
          This is a computer-generated document and does not require a
          signature.
        </p>
      </div>
    </div>
  );
});

export default PoFileDownload;
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    minHeight: "100vh",
    position: "relative",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  declaration: {
    marginTop: "20px",
    borderTop: "1px solid #ccc",
    paddingTop: "10px",
    pageBreakInside: "avoid", // Avoid breaking within the declaration section
  },
  signatureSection: {
    marginTop: "20px",
    textAlign: "right",
    width: "100%",
    pageBreakInside: "avoid", // Prevent splitting the signature
    pageBreakAfter: "avoid", // Ensure it doesn't push unnecessarily to the next page
  },
  footer: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "14px",
    color: "#555",
    pageBreakInside: "avoid", // Prevent breaking within the footer
  },
  address: { lineHeight: "1.5", padding: 5, width: "100%" },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  headerTitle: {
    margin: 0,
    fontSize: "24px",
  },
  watermark: {
    textAlign: "center",
    position: "fixed",
    width: "100%",
    top: "30%",
    left: "10%",
    transform: "translate(-50%, -50%)",
    opacity: 0.1,
    zIndex: 0,
    pointerEvents: "none",
    fontSize: "100px",
    color: "#4e4a4a",
    fontWeight: "bold",
    transformOrigin: "center",
    transform: "rotate(-45deg)",
  },
  infoSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "20px",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  logo: {
    width: "150px",
    height: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  tableHeader: {
    backgroundColor: "#f4f4f4",
  },
  tableCell: {
    border: "1px solid #807c7c",
    textAlign: "center",
    padding: "2px",
  },
  summary: {
    textAlign: "right",
    fontWeight: "bold",
  },

  signature: {
    marginTop: "80px",
  },
};
const printStyles = `
  @media print {
    .container {
      display: flex;
      flex-direction: column;
    }
    .signatureSection {
      page-break-before: auto; /* Let it fit in the current page if space allows */
    }
  }
`;
