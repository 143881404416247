/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { skuBookingButtonStyle } from "./buttonStyles";
export default function OrderButtonSku({
  handelMinusQuantity,
  handelAddQuantity,
  handelQuantityChange,
  inputValue,
  disabled,
  type,
}) {
  return (
    <>
      <Stack direction="row">
        <Box sx={skuBookingButtonStyle.orderButtonStyle}>
          <Button size="small" onClick={handelMinusQuantity}>
            <RemoveIcon
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#fff",
              }}
            />
          </Button>
        </Box>
        <Box sx={skuBookingButtonStyle.canQtyStyle}>
          {" "}
          <input
            type={type || "number"}
            value={inputValue}
            onChange={handelQuantityChange}
            style={{
              wordWrap: "normal",
              border: 0,
              fontSize: "16px",
              minWidth: "20px",
              textAlign: "center",
              width: "100%",
              outline: "none",
            }}
            disabled={false}
          />
        </Box>
        <Box sx={skuBookingButtonStyle.orderButtonStylePlus}>
          <Button size="small" onClick={handelAddQuantity}>
            <AddIcon
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#fff",
              }}
            />
          </Button>
        </Box>
      </Stack>
    </>
  );
}
