/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
// Importing the Libraries and Other Files
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import * as Material from "@mui/material";
import Radio from "@mui/material/Radio";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { AddingNewPincodes } from "../../Pages/Redux/Actions/pincodeAndServiceAreaAction";
import { toast } from "react-toastify";
import {
  AddPincodeSeriviceAreaSku,
  DeleteProductFromLocality,
  UpdatePincodePriceSku,
} from "../../Pages/Redux/Actions/skuPincodeAction";
import ConformationDialog from "./conformationDialog";
import CustomConsole from "../../CustomConsole";
import AutocompleteAddressFields from "../zoneComponents/AutocompleteAddressFields";

function AddUpdatePicodeAreaSku({
  openPincodeDialog,
  setOpenPincodeDialog,
  updatePincodeData,
  setupdatePincodeData,
  selectedPincodes,
  availableZoneNames,
}) {
  const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  let initialPicodeData = {
    pincode: "",
    country: "",
    country_code: "+91",
    district: "",
    state: "",
    locality: [],
    products: [],
  };
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [pincodeData, setPincodeData] = useState(initialPicodeData);
  const [serviceArea, setServiceArea] = React.useState("");
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [blockProductPincode, setBlockProductPincode] = React.useState(3);
  const [errors, setErrors] = React.useState({});
  const [productList, setProductList] = React.useState({
    product_id: "",
    booking_type: "",
    name: "",
    available_for: 3,
    refill: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 100,
      refund_amount: 0,
    },
    one_time_sale: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 0,
      refund_amount: 0,
    },
  });
  const [deleteProduct, setDeleteProduct] = React.useState({
    locality_id: "",
    products: [],
  });
  const [zoneName, setZoneName] = React.useState("");
  const [zoneType, setZoneType] = React.useState("City");
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const updateFlag = Object.keys(updatePincodeData || {}).length > 0;
  console.log("-------------updatePincodeData-----------");
  console.log(updatePincodeData);
  const dispatch = useDispatch();
  //variable for different Refund types
  const refundTypes = [
    {
      label: "WALLET",
      value: "100",
    },
    {
      label: "WATER DROPS",
      value: "200",
    },
    {
      label: "None",
      value: "0",
    },
  ];

  //useEffect for setting up initial data in state
  console.log(updateFlag);
  React.useEffect(() => {
    console.log(updatePincodeData?.zone_type);
    //@Removed/console.log(updatePincodeData.products);
    if (updateFlag) {
      setPincodeData((prev) => ({
        products: updatePincodeData.products,
        locality_ids: [updatePincodeData.id],
      }));
      setZoneName(updatePincodeData?.zone_name || "");
      setZoneType(updatePincodeData?.zone_type || "");
    }
  }, [updatePincodeData, updateFlag]);

  // handle change function for adding pincode details
  const handelChangePicodeData = (event) => {
    const { name, value } = event.target;
    let pinCodeRegex = /^[1-9][0-9]{5}$/;
    if (name === "pincode" && !pinCodeRegex.test(value)) {
      setErrors(true);
      setPincodeData((prev) => ({ ...prev, [name]: value }));
    } else {
      setErrors(false);
      setPincodeData((prev) => ({ ...prev, [name]: value }));
    }
  };

  //UseEffect for validation
  React.useEffect(() => {
    if (pincodeData.country?.trim().length < 3) {
      setErrors(true);
    } else if (pincodeData.district?.trim().length < 3) {
      setErrors(true);
    } else if (pincodeData.state?.trim().length < 3) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [pincodeData]);
  React.useState(() => {
    //@Removed/console.log(updatePincodeData.products);
    if (
      updatePincodeData?.products !== null &&
      updatePincodeData?.products !== undefined &&
      updatePincodeData?.products.length > 0
    ) {
      updatePincodeData.products?.filter((data) => {
        return setBlockProductPincode(
          data.available_for !== undefined && data.available_for !== null
            ? data.available_for
            : 3
        );
      });
      // setBlockProductPincode(pincodeData.products[0].available_for);
    }
  }, [updatePincodeData]);

  // handle change function for adding locality
  const handleAddServiceArea = () => {
    if (serviceArea?.trim() !== "") {
      setPincodeData((prev) => ({
        ...prev,
        locality: [...prev.locality, serviceArea],
      }));
      setServiceArea("");
    } else {
    }
  };

  // remove area from locality array
  const removeAreaFrArr = (idx) => {
    const newLocality = [...pincodeData.locality];
    newLocality.splice(idx, 1);
    setPincodeData((prev) => ({
      ...prev,
      locality: newLocality,
    }));
  };

  // function for closing dialog
  const handelClose = () => {
    setOpenPincodeDialog(false);
    setPincodeData(initialPicodeData);
    setupdatePincodeData({});
  };

  //function for handling product selection change
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = skuContainerDetails.getAvailableProducts.find(
      (data) =>
        `${data.product_details.capacity}-${data.product_details.material}` ===
        event.target.value
    );

    if (selectedDetails) {
      setProductList((prev) => ({
        ...prev,
        name: `${selectedDetails.product_details.capacity}-${selectedDetails.product_details.material}`,
        capacity: selectedDetails.product_details.capacity,
        material: selectedDetails.product_details.material,
        product_id: selectedDetails.product_details.id,
        booking_type: selectedDetails.product_details.booking_type,
        refill: {
          ...prev.refill,
          mrp: selectedDetails.product_details.refill.mrp,
          sp: selectedDetails.product_details.refill.sp,
          deposit: selectedDetails.product_details.refill.deposit,
          refund_type: selectedDetails.product_details.refill.refund_type,
          refund_amount: selectedDetails.product_details.refill.refund_amount,
        },
        one_time_sale: {
          ...prev.one_time_sale,
          mrp: selectedDetails.product_details.one_time_sale.mrp,
          sp: selectedDetails.product_details.one_time_sale.sp,
          deposit: selectedDetails.product_details.one_time_sale.deposit,
          refund_type:
            selectedDetails.product_details.one_time_sale.refund_type,
          refund_amount:
            selectedDetails.product_details.one_time_sale.refund_amount,
        },
      }));
    }
  };

  //function for handling add product detaisls
  const handelAddProduct = () => {
    if (Object.keys(productList).length > 0) {
      let duplicateElement = pincodeData.products.filter(
        (data) => data.product_id === productList.product_id
      );
      if (duplicateElement.length === 0) {
        setPincodeData((prev) => ({
          ...prev,
          products: [...prev.products, productList],
        }));
        setProductList({});
      }
    } else {
    }
  };
  const alphabetPattern = /^[a-zA-Z]*$/;

  //function for handling product change
  const handleProductDetailsChange = (event, index, saleType, field) => {
    const newValue =
      event.target.value.trim() !== "" ? parseFloat(event.target.value) : 0;
    setPincodeData((prev) => ({
      ...prev,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [saleType]: {
                ...product[saleType],
                [field]: newValue,
              },
            }
          : product
      ),
    }));
  };

  //function for handling product Booking type change
  const handleProductBookingTypeChange = (value, index, field) => {
    const newValue = value;
    CustomConsole(newValue);
    setPincodeData((prev) => ({
      ...prev,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [field]: newValue,
            }
          : product
      ),
    }));
  };

  //useEffect for closing dialog after sucessfull adding of pincode
  React.useEffect(() => {
    if (!skuPincodeDetails.addPincodeError) {
      handelClose();
      setPincodeData(initialPicodeData);
    }
  }, [skuPincodeDetails.addPincodeError]);

  CustomConsole(skuPincodeDetails);
  //function for updating data
  const handelUpdateData = () => {
    if (!updateFlag) {
      const finalProducts = [];
      const zoneTypeMapping = {
        City: 100,
        "Mandal/Post": 200,
      };

      const reqObj = {
        zone_name: zoneName,
        zone_type: zoneTypeMapping[zoneType],
        pincode: selectedPincodes,
      };
      for (const data of pincodeData.products) {
        finalProducts.push({
          ...data,
          available_for: blockProductPincode,
        });
      }

      dispatch(
        AddPincodeSeriviceAreaSku({
          ...reqObj,
          ...pincodeData,
          products: finalProducts,
        })
      );
    } else {
      UpdatePincodePrice(pincodeData);
    }
  };

  //function for updating pincode price detaisl
  const UpdatePincodePrice = () => {
    const finalProducts = [];
    const zoneTypeMapping = {
      City: 100,
      "Mandal/Post": 200,
    };
    const reqObj = {
      zone_name: zoneName,
      zone_type: zoneType,
      pincode: selectedPincodes,
    };
    for (const data of pincodeData.products) {
      finalProducts.push({
        ...data,
        available_for: blockProductPincode,
      });
    }
    dispatch(
      UpdatePincodePriceSku({
        ...reqObj,
        ...pincodeData,
        products: finalProducts,
      })
    );
  };

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.updatePincodeError) {
      handelClose();
      setPincodeData(initialPicodeData);
      setupdatePincodeData({});
    }
  }, [skuPincodeDetails.updatePincodeError]);

  //function for opening conformation dialog
  const handelDeleteProduct = (value, idx) => {
    const newProductList = [...pincodeData.products];
    if (value.name === undefined) {
      setConfirmDialog(true);
      setDeleteProduct((prev) => ({
        locality_id: updatePincodeData.id,
        products: [value.product_id],
      }));
    } else {
      newProductList.splice(idx, 1);
      setPincodeData((prev) => ({
        ...prev,
        products: newProductList,
      }));
    }
  };

  //function for deleting product from locality
  const handelConformDelete = () => {
    dispatch(DeleteProductFromLocality(deleteProduct));
    setConfirmDialog(false);
  };

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.deleteProductError) {
      handelClose();
    }
  }, [skuPincodeDetails.deleteProductError]);

  return (
    <Box>
      <Dialog open={openPincodeDialog} onClose={handelClose}>
        <DialogContent>
          <DialogTitle>Add/Update Pincode</DialogTitle>
          <Card>
            <CardContent>
              {!updateFlag ? (
                <>
                  <Stack spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          type="number"
                          label="Pincode*"
                          name="pincode"
                          size="small"
                          value={pincodeData.pincode}
                          // inputProps={{
                          //   maxLength: 6,
                          // }}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          onChange={handelChangePicodeData}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <AutocompleteAddressFields
                          key_name="district"
                          label="District*"
                          // setValue={setPincodeData}
                          setValue={(value) =>
                            handelChangePicodeData({
                              target: { name: "district", value: value },
                            })
                          }
                          value={pincodeData?.district}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <AutocompleteAddressFields
                          key_name="state_name"
                          label="State*"
                          setValue={(value) =>
                            handelChangePicodeData({
                              target: { name: "state", value: value },
                            })
                          }
                          value={pincodeData?.state}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <AutocompleteAddressFields
                          key_name="country"
                          label="Country*"
                          setValue={(value) =>
                            handelChangePicodeData({
                              target: { name: "country", value: value },
                            })
                          }
                          value={pincodeData?.country}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <AutocompleteAddressFields
                          key_name="country_code"
                          label="Country Code"
                          setValue={(value) =>
                            handelChangePicodeData({
                              target: { name: "country_code", value: value },
                            })
                          }
                          value={pincodeData?.country_code}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <AutocompleteAddressFields
                          key_name="zone_name"
                          label="Zone Name*"
                          setValue={setZoneName}
                          value={zoneName}
                        />{" "}
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <FormControl
                          size="small"
                          sx={{
                            marginTop: "0px",
                            padding: "0px",
                          }}
                          fullWidth
                          margin="normal"
                        >
                          <InputLabel id="zone-type-label">
                            Zone Type
                          </InputLabel>
                          <Select
                            labelId="zone-type-label"
                            size="small"
                            value={zoneType}
                            onChange={(e) => setZoneType(e.target.value)}
                            label="Zone Type*"
                          >
                            <MenuItem value="City">City</MenuItem>
                            <MenuItem value="Mandal/Post">Mandal/Post</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid
                        container
                        sm={12}
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ marginTop: "1rem" }}
                      >
                        <TextField
                          type="text"
                          label="Service Area*"
                          name="locality"
                          size="small"
                          value={serviceArea}
                          onChange={(e) => setServiceArea(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={handleAddServiceArea}
                                >
                                  Add
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                    {pincodeData?.locality?.map((val, idx) => (
                      <Grid item key={idx}>
                        <Box
                          sx={{
                            padding: "5px",
                            backgroundColor: "#C9EEFF",
                            display: "flex",
                            borderRadius: "10px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              fontFamily: "sans-serif",
                            }}
                          >
                            {val.length > 15 ? val.slice(0, 15) + "...." : val}
                          </Typography>
                          <CloseIcon
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              removeAreaFrArr(idx);
                            }}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  <Typography>
                    LOCALITY:{updatePincodeData?.locality}
                  </Typography>
                  <Grid container marginTop={"10px"} spacing={2}>
                    <Grid item sm={6} xs={12}>
                      {/* <FormControl
                        size="small"
                        sx={{
                          marginTop: "0px",
                          padding: "0px",
                        }}
                        fullWidth
                        margin="normal"
                      >
                        <InputLabel id="zone-type-label">Zone Type</InputLabel>
                        <Select
                          labelId="zone-type-label"
                          size="small"
                          value={zoneType}
                          onChange={(e) => setZoneType(e.target.value)}
                          label="Zone Type"
                        >
                          <MenuItem value={100}>City</MenuItem>
                          <MenuItem value={200}>Mandal/Post</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>
                    {/* <Grid item sm={6} xs={12}>
                      <AutocompleteAddressFields
                        key_name="zone_name"
                        label="Zone Name*"
                        setValue={setZoneName}
                        value={zoneName}
                      />{" "}
                    </Grid> */}
                  </Grid>
                </>
              )}
              {!pincodeData?.products.length &&
                "Note : Please Add Product for this area"}
              <Grid
                container
                spacing={2}
                sx={{ marginTop: ".5rem" }}
                justifyContent="center"
                alignItems="center"
              >
                <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
                  <InputLabel id="demo-select-small">Product</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productList.name}
                    label="Capacity"
                    onChange={handleChangeSelectedProduct}
                  >
                    {skuContainerDetails.getAvailableProducts.map((data) => (
                      <MenuItem
                        value={`${data.product_details.capacity}-${data.product_details.material}`}
                        key={data.product_details.id}
                      >
                        <div>
                          <span>
                            {" "}
                            <strong>{data.product_details.name}</strong>
                          </span>
                          <br />
                          <small>
                            Capacity:
                            {data.product_details.capacity}/
                          </small>
                          <small>
                            Material:
                            {data.product_details.material}
                          </small>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={handelAddProduct}
                  variant="contained"
                  size="small"
                >
                  Add
                </Button>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: ".5rem" }}
                justifyContent="start"
                alignItems="start"
              >
                <Typography sx={{ mt: 1.5 }}>Available For :</Typography>
                <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
                  <InputLabel>Order Type</InputLabel>
                  <Select
                    value={blockProductPincode}
                    label="Order Type"
                    onChange={(e) => setBlockProductPincode(e.target.value)}
                  >
                    <MenuItem value={1}>PREPAID</MenuItem>
                    <MenuItem value={2}>POSTPAID</MenuItem>
                    <MenuItem value={3}>BOTH</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                {pincodeData.products.map((val, idx) => (
                  <Grid item xs={12} key={idx}>
                    <Material.Grid item md={12} sm={12} xs={12}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 1}
                          value={val.booking_type}
                          label="Refill"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              1,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 2}
                          value={val.booking_type}
                          label="One time sale"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              2,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 3}
                          value={val.booking_type}
                          label="Both"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              3,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <>
                          <DeleteIcon
                            sx={{ color: "red" }}
                            onClick={() => handelDeleteProduct(val, idx)}
                          />
                        </>
                      </Box>
                    </Material.Grid>
                    <Box
                      sx={{
                        border: ".5px solid gray",
                        borderRadius: "10px",
                        padding: ".5rem",
                      }}
                    >
                      {val.booking_type == 2 || val.booking_type == 3 ? (
                        <>
                          <Typography>
                            {val.name}
                            {val.name !== undefined ? "/" : null} {val.capacity}
                            {val.material !== undefined ? "/" : null}
                            {val.material}/One Time Sale
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              marginTop: "1rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Deposit"
                              name="deposit"
                              size="small"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              value={val.one_time_sale.deposit}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "deposit"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="MRP"
                              name="mrp"
                              size="small"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "mrp"
                                )
                              }
                              value={val.one_time_sale.mrp}
                            />
                            <Material.TextField
                              id="outlined-select-currency-native"
                              select
                              size="small"
                              label="Refund Type"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              defaultValue={val.one_time_sale.refund_type}
                              SelectProps={{
                                native: true,
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "refund_type"
                                )
                              }
                            >
                              {refundTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Material.TextField>
                            <TextField
                              type="number"
                              label="Refund Amount"
                              name="refund_amount"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              size="small"
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "refund_amount"
                                )
                              }
                              value={val.one_time_sale.refund_amount}
                            />
                            <TextField
                              type="number"
                              label="Selling Price"
                              name="sp"
                              size="small"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              value={val.one_time_sale.sp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "sp"
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : null}
                      {val.booking_type == 1 || val.booking_type == 3 ? (
                        <>
                          <Typography>
                            {" "}
                            {val.name}
                            {val.name !== undefined ? "/" : null} {val.capacity}
                            {val.material !== undefined ? "/" : null}
                            {val.material}/Refill Sale
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              flexWrap: "wrap",
                              marginTop: "1rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Deposit"
                              name="deposit"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              size="small"
                              value={val.refill.deposit}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "deposit"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="MRP"
                              name="mrp"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              size="small"
                              value={val.refill.mrp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "mrp"
                                )
                              }
                            />
                            <Material.TextField
                              id="outlined-select-currency-native"
                              select
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              size="small"
                              label="Refund Type"
                              defaultValue={val.refill.refund_type}
                              SelectProps={{
                                native: true,
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "refund_type"
                                )
                              }
                            >
                              {refundTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Material.TextField>
                            <TextField
                              type="number"
                              label="Refund Amount"
                              name="refund_amount"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              size="small"
                              value={val.refill.refund_amount}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "refund_amount"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="Selling Price"
                              sx={{
                                width: { xs: "7rem" },
                              }}
                              name="country"
                              size="small"
                              value={val.refill.sp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "sp"
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
            <ConformationDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              dialogMsg="Are you sure to delete Product from Locality"
              handelProceed={handelConformDelete}
            />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelClose}>Close</Button>
          <Button
            disabled={errors}
            onClick={handelUpdateData}
            variant="contained"
            size="small"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddUpdatePicodeAreaSku;
