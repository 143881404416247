import { useDispatch } from "react-redux";
import { GetDepositAndUsersWalletSummary } from "../../Pages/Redux/Actions/NewDashboardAction";
import { AvailablePincodes } from "../../Pages/Redux/Actions/pincodesAction";
import { useEffect, useState } from "react";
import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";
import { styles } from "../../Pages/AdminOrders/ordersStyle";

const RegionFilters = ({
  selectedCountry = "INDIA",
  setSelectedCountry,

  setFilterhouseCity,
  setSelectedState,
  selectedState,
}) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState();
  const [districts, setDistricts] = useState();
  const getCountries = async () => {
    const all_available_countries = await AvailablePincodes({
      // pincode: value,
      page_number: 1,
      page_size: 100,
      get: "country",
    });
    setCountries(all_available_countries?.data);
    // setAllAvailableCountries(all_available_countries?.data);
  };
  const getStates = async (params) => {
    const all_states = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "state_name",
    });
    setStates(all_states?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  const getDistricts = async (params) => {
    const all_Districts = await AvailablePincodes({
      // pincode: value,
      ...params,
      page_number: 1,
      page_size: 500,
      get: "district",
    });
    setDistricts(all_Districts?.data);
    // setAllAvailableDistricts(all_Districts?.data);
  };
  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      getStates({ country: selectedCountry });
    }
    setSelectedState("");
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedState && selectedCountry) {
      //   getDistricts({ country: selectedCountry });
      getDistricts({
        country: selectedCountry,
        state: selectedState,
      });
    }
    setFilterhouseCity("");
  }, [selectedState]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          ...styles.foMainBox,
        }}
      >
        <p
          style={{
            ...styles.filterHeader,
          }}
        >
          Country
        </p>
        <Autocomplete
          value={selectedCountry}
          fullWidth
          options={countries || []}
          autoHighlight
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.country
          }
          onChange={(_, newValue) => {
            setSelectedCountry(newValue ? newValue.country : ""); // Ensure correct value is set
          }}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option} value={option.country}>
              {option.country}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // Disable browser autocomplete
              }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          ...styles.foMainBox,
        }}
      >
        <p
          style={{
            ...styles.filterHeader,
          }}
        >
          State{" "}
        </p>

        <Autocomplete
          value={selectedState}
          fullWidth
          options={states || []}
          autoHighlight
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.state_name
          }
          onChange={(_, newValue) => {
            setSelectedState(newValue ? newValue?.state_name : ""); // Set the selected  as the value
          }}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              key={option.state_name}
              value={option.state_name?.toUpperCase()}
            >
              {option.state_name?.toUpperCase()}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>

      {selectedState ? (
        <Box
          sx={{
            ...styles.foMainBox,
          }}
        >
          <p
            style={{
              ...styles.filterHeader,
            }}
          >
            City{" "}
          </p>
          <Autocomplete
            id="country-select-demo"
            fullWidth
            options={
              districts?.map((item, index) => {
                return { name: item.district };
              }) || []
            }
            autoHighlight
            getOptionLabel={(nameSearch) => `${nameSearch?.name || ""} `}
            onChange={(_, newValue) => {
              setFilterhouseCity(newValue?.name || null);
            }}
            renderOption={(props, nameSearch) => (
              <Box sx={{ fontSize: "16px" }} component="li" {...props}>
                {nameSearch.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                autoComplete={false}
                aria-autocomplete="none"
                {...params}
                size="small"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null,
                  autoComplete: "off",
                }}
              />
            )}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default RegionFilters;
