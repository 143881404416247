/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import {
  Box,
  Paper,
  TextField,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSupplierDetailsList } from "../Redux/Actions/supplierVehiclesActions";
import { GetFilteredUserAddressAdmin } from "../Redux/Actions/userDetailsAdminAction";

function BillingAddress({ addresses, setAddresses }) {
  const [warehouses, setwarehouses] = useState();
  const dispatch = useDispatch();

  const [suppliersList, setSuppliersList] = useState([]);

  //function to get countries details
  React.useEffect(() => {
    // getCountries();
    GetWareHouseDetailsList();
  }, []);

  React.useEffect(() => {
    if (suppliersList?.length) {
      // Filter out suppliers where supplier_name is null
      const validSuppliers = suppliersList.filter(
        (supplier) => supplier.supplier_name !== null
      );

      // If there are valid suppliers, select the first one
    }
  }, [suppliersList]);

  //function to get WareHouse Details details based on address_id
  React.useEffect(() => {
    if (addresses?.billing_address?.user_id) {
      //   getDistricts({ country: selectedCountry });
      GetWareHouseDetails({
        user_id: addresses?.billing_address?.user_id,
      });
    }
  }, [addresses?.billing_address?.user_id]);
  useEffect(() => {
    if (!addresses.billing_address && addresses.delivery_address) {
      setAddresses((prev) => ({ ...prev, delivery_address: null }));
    }
  }, [addresses]);

  //function to download warehouse details List
  function GetWareHouseDetailsList(data) {
    console.log("WareHouseDetails");
    console.log(data);
    // dispatch(WareHouseDetailsList());
    dispatch(GetSupplierDetailsList()).then((data) => {
      setSuppliersList(data?.data || []);
    });
  }

  //function to download warehouse details
  function GetWareHouseDetails(data) {
    console.log("WareHouseDetails");
    console.log(data);
    dispatch(
      GetFilteredUserAddressAdmin({
        user_id: data.user_id,
      })
    ).then((data) => {
      setwarehouses(data?.addresses);
    });
  }
  return (
    <Box>
      {/* Top Bar for Filter & Search Options */}

      <Box
        sx={{
          mt: "10px",
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          // alignItems: "center",
          // justifyContent: "end",
        }}
      >
        <Typography sx={cardStyles.typoHeader}>
          Billing Address :
          {addresses?.billing_address?.supplier_profile ? (
            <div style={cardStyles.spanText}>
              {[
                addresses?.billing_address?.supplier_profile?.company_name,
                addresses?.billing_address?.supplier_profile?.address_line1,
                addresses?.billing_address?.supplier_profile?.address_line2,
                addresses?.billing_address?.supplier_profile?.address_line3,
                addresses?.billing_address?.supplier_profile?.city,
                addresses?.billing_address?.supplier_profile?.state,
                `Pincode - ${addresses?.billing_address?.supplier_profile?.pincode}`,
              ]?.join(", ")}
              <br />
              GSTIN: {addresses?.billing_address?.supplier_profile?.gst}
            </div>
          ) : (
            <></>
          )}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disabled={!suppliersList?.length}
            id="billing-select-demo"
            sx={{ width: 210, float: "right" }}
            options={
              suppliersList?.filter(
                (item) => item?.supplier_profile?.company_name !== null
              ) // Filter out null supplier names
            }
            value={addresses?.billing_address} // Set the value of Autocomplete to the selected supplier
            isOptionEqualToValue={(option, value) =>
              option.user_id === value?.user_id
            }
            autoHighlight
            getOptionLabel={(option) =>
              option.supplier_profile?.company_name || ""
            } // Handle case where contact_name is null
            onChange={(_, newValue) => {
              setAddresses((prev) => {
                return { ...prev, billing_address: newValue };
              });
              // Update the selected supplier when user changes
            }}
            renderOption={(props, option) => {
              if (!option.supplier_profile?.company_name) {
                return <></>;
              }
              return (
                <Box component="li" {...props}>
                  {option.supplier_profile?.company_name}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                label={
                  suppliersList?.length
                    ? "Select/Edit Billing Address"
                    : "No Billing Info Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // Disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          // alignItems: "center",
          // justifyContent: "end",
        }}
      >
        <Typography sx={cardStyles.typoHeader}>
          Delivery Address :{" "}
          {addresses?.delivery_address ? (
            <div style={cardStyles.spanText}>
              {[
                addresses?.delivery_address?.contact_name,
                addresses?.delivery_address?.address_line1,
                addresses?.delivery_address?.address_line2,
                addresses?.delivery_address?.address_line3,
                addresses?.delivery_address?.city,
                addresses?.delivery_address?.state,
                `Pincode - ${addresses?.delivery_address?.pincode}`,
              ]?.join(", ")}
              <br />
            </div>
          ) : (
            <></>
          )}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disabled={!warehouses?.length}
            id="delivery-select-demo"
            sx={{ width: 210, float: "right" }}
            options={warehouses || []}
            autoHighlight
            getOptionLabel={(nameSearch) =>
              `${nameSearch.contact_name} ${nameSearch.pincode}`
            }
            onChange={(_, newValue) => {
              setAddresses((prev) => {
                return { ...prev, delivery_address: newValue };
              }); // Set the selected  as the value
            }}
            renderOption={(props, nameSearch) => (
              <Box
                component="li"
                // sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {nameSearch.contact_name} - {nameSearch.pincode}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                label={
                  warehouses?.length
                    ? "Select/Edit Delivery address"
                    : "No warehouses Found"
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: null, // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BillingAddress;
const cardStyles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
  },
  manuFactureDetails: {
    fontSize: "1.2rem",
    fontWeight: 500,
    textAlign: "start",
  },
  typoHeader: { fontSize: "1rem", fontWeight: "Bold" },
  spanText: { fontSize: ".9rem", fontWeight: "500" },
};
