import {
  DeleteOutlineRounded,
  KeyboardBackspaceRounded,
  Padding,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import PrimaryButton from "../../Components/Buttons/primaryButton";
import {
  DownLoadDraftManufacturerPo,
  GetMftOrderPoDraftData,
  PlaceManufactureOrder,
  ValidateManufactureOrder,
} from "../Redux/Actions/bookWaterStockAction";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { useDispatch } from "react-redux";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import { toast } from "react-toastify";
import PoFileDownload from "./PoFileDownload";
import { GetSupplierDetailsList } from "../Redux/Actions/supplierVehiclesActions";
import BillingAddress from "./BillingAddress";
import EditIcon from "@mui/icons-material/Edit";
import { INV_TAX_TYPES } from "../../Components/AppMeta/appMetaConfig";
import TextEditor from "../../Components/TextEditor";

export default function ValidateManufactureOrderView({
  setCartPageFlag,
  validateData,
  addresses,
  setAddresses,
  setValidateData,
  setSelectedToggelOption,
  setSelectedProducts,
  declaration,
  setDeclaration,
}) {
  console.log("validateData...................");
  console.log(validateData);
  const dispatch = useDispatch();

  // const [taxType, setTaxType] = useState(
  //   addresses?.is_same_state ? [INV_TAX_TYPES.STATE] : [INV_TAX_TYPES.CENTRAL]
  // );
  const [poData, setPoData] = useState();
  const [orderSuccess, setOrderSuccess] = useState({});
  const [editDeclaration, setEditDeclaration] = useState(false);
  const handelDataChange = (event, data, productData, field) => {
    const value = event.target.value;
    const mfgId = data?.mft_id;
    const name = `${productData?.capacity}_${productData?.material}`;
    setValidateData((prev) => {
      return prev?.map((data) => {
        if (data?.mft_id === mfgId) {
          return {
            ...data,
            products: data?.products.map((product) => {
              if (`${product?.capacity}_${product?.material}` === name) {
                return {
                  ...product,
                  [field]: value,
                };
              }
              return product;
            }),
          };
        }
        return data;
      });
    });
  };

  //function to delete not required product
  const handelDelete = (data, productData) => {
    const mfgId = data?.mft_id;
    const name = `${productData?.capacity}_${productData?.material}`;

    setValidateData((prev) => {
      return prev
        .map((manufacturer) => {
          if (manufacturer?.mft_id === mfgId) {
            if (manufacturer?.products?.length > 1) {
              return {
                ...manufacturer,
                products: manufacturer?.products?.filter(
                  (product) =>
                    `${product?.capacity}_${product?.material}` !== name
                ),
              };
            }
            if (manufacturer?.products?.length === 1) {
              return null;
            }
          }
          return manufacturer;
        })
        .filter(Boolean);
    });
  };

  //function to update order data to verify
  const handelUpdateOrder = () => {
    let reqObject = {
      order_type: 100,
      orders: validateData,
    };
    dispatch(ValidateManufactureOrder(reqObject)).then((details) => {
      const { data } = details;
      if (!data?.error) {
        setValidateData(data?.data?.orders);
      }
    });
  };

  //function to handel download po
  const hnadelDownLoadPo = () => {
    let reqObject = {
      order_type: 100,
      orders: validateData,
      addresses,
      declaration,
    };
    dispatch(GetMftOrderPoDraftData(reqObject)).then((data) => {
      if (!data?.error && data?.data) {
        setPoData(data.data);
      }
    });
  };

  //function to handel place order
  const handelPlaceOrder = () => {
    let reqObject = {
      order_type: 100,
      orders: validateData,
      addresses,
      declaration,
    };
    dispatch(PlaceManufactureOrder(reqObject)).then((details) => {
      console.log(details);
      const { data } = details;
      if (!data.error) {
        setCartPageFlag(false);
        setValidateData([]);
        setOrderSuccess(data);
        setSelectedToggelOption("orderDetails", data?.data?.[0]);
        setSelectedProducts([]);
      }
    });
  };
  console.log(orderSuccess);
  const getBillingAddress = async () => {
    dispatch(GetSupplierDetailsList()).then((data) => {});
  };
  return (
    <>
      {orderSuccess?.error == undefined ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handelUpdateOrder();
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => setCartPageFlag(false)}
            >
              <KeyboardBackspaceRounded />
            </IconButton>
            <Typography sx={cardStyles.typoHeader}>Order Details</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {validateData?.map((data) => (
              <Card sx={{ width: "100%" }}>
                <CardContent>
                  <Box sx={cardStyles.mainBox}>
                    <Box sx={cardStyles.manuFactureDetails}>
                      <Typography sx={cardStyles.typoHeader}>
                        Manufacturer :{" "}
                        <span style={cardStyles.spanText}>
                          {data?.mft_details?.manufacturer_name}
                        </span>
                      </Typography>
                      <Typography sx={cardStyles.typoHeader}>
                        Branch :
                        <span style={cardStyles.spanText}>
                          {data?.mft_details?.branch}
                        </span>
                      </Typography>
                      <BillingAddress
                        addresses={addresses}
                        setAddresses={setAddresses}
                      />
                    </Box>
                    <div
                      style={{
                        width: "100%",
                        border: "1px dashed #808080",
                        margin: ".5rem",
                      }}
                    />
                    <Box sx={cardStyles.manuFactureDetails}>
                      <Typography sx={cardStyles.typoHeader}>
                        Product Details
                      </Typography>
                      <Grid container>
                        {data?.products?.map((product, index) => (
                          <MftOrderProduct
                            data={data}
                            handelDataChange={handelDataChange}
                            handelDelete={handelDelete}
                            product={product}
                            // taxType={taxType}
                            key={index}
                          />
                        ))}
                      </Grid>
                    </Box>
                    {data?.order_summary?.order_total && (
                      <Box>
                        <Typography
                          sx={{
                            color: "green",
                            fontSize: ".9rem",
                            textAlign: "start",
                          }}
                        >
                          Order Total: ₹ {data?.order_summary?.order_total}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
          <Box sx={{ display: "flex", mt: 5 }}>
            <Box sx={{ fontWeight: "bold" }}>
              Declaration:{" "}
              <span onClick={() => setEditDeclaration((prev) => !prev)}>
                {editDeclaration ? (
                  <Button>
                    Save <SaveAsIcon color="success" />
                  </Button>
                ) : (
                  <Button>
                    Edit <BorderColorIcon />
                  </Button>
                )}
              </span>
            </Box>
          </Box>
          {editDeclaration ? (
            <TextEditor
              tools={true}
              curentValue={declaration}
              onChange={setDeclaration}
            />
          ) : (
            <div
              style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: declaration || "" }}
            ></div>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <PrimaryButton
              btnName="Update"
              type="submit"
              // handleButtonClick={() => handelUpdateOrder()}
              color={appColors.commonBtnColor}
            />
            <PrimaryButton
              btnName="Draft Po"
              handleButtonClick={() => hnadelDownLoadPo()}
              color={appColors.commonBtnColor}
            />
            <PrimaryButton
              btnName="Place Order"
              handleButtonClick={() => handelPlaceOrder()}
              color={appColors.commonBtnColor}
            />
          </Box>
        </form>
      ) : (
        <Typography sx={cardStyles.typoHeader}>
          {`${orderSuccess?.msg} ${orderSuccess?.data[0]}`}
        </Typography>
      )}
      <Box sx={{ display: "none" }}>
        {poData && <PoFileDownload data={poData} />}
      </Box>
    </>
  );
}

function MftOrderProduct({
  product,
  handelDataChange,
  data,
  handelDelete,
  // taxType,
}) {
  const [editProductDesc, setEditProductDesc] = useState(
    product.custom_desc ? true : false
  );
  return (
    <Grid item>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          marginBottom: ".5rem",
          flexWrap: "wrap",
          color: !product?.is_valid ? "red" : "black",
        }}
      >
        <Box>
          <Typography>{`${product?.capacity}/${product?.material}`}</Typography>
          {/* <EditIcon />
          <Box sx={{ width: "100%" }}>
            <TextEditor />
          </Box> */}
        </Box>
        <TextField
          required
          variant="outlined"
          label="Quantity"
          size="small"
          sx={{ width: "6rem" }}
          onKeyDown={(event) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
              event.preventDefault();
            }
          }}
          type="number"
          value={product?.quantity || ""}
          onChange={(event) =>
            handelDataChange(event, data, product, "quantity")
          }
        />
        <TextField
          required
          variant="outlined"
          sx={{ width: "6rem" }}
          size="small"
          label="Unit Price"
          type="number"
          value={product?.price || ""}
          onKeyDown={(event) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
              event.preventDefault();
            }
          }}
          onChange={(event) => handelDataChange(event, data, product, "price")}
        />

        <DeleteOutlineRounded onClick={() => handelDelete(data, product)} />
      </Box>
      {product?.is_valid && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: ".9rem",
                textAlign: "start",
              }}
            >
              Total Price: ₹ {product?.grand_total}
            </Typography>
          </Box>
          {!data?.addresses?.is_same_state ? (
            <>
              <Box>
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    textAlign: "start",
                  }}
                >
                  IGST:{" "}
                  {`₹${product?.sgst_amt + product?.cgst_amt} (${
                    product?.sgst_perc + product?.cgst_perc
                  }%)`}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    textAlign: "start",
                  }}
                >
                  SGST: {`₹${product?.sgst_amt} (${product?.sgst_perc}%)`}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    textAlign: "start",
                  }}
                >
                  CGST: {`₹${product?.cgst_amt} (${product?.cgst_perc}%)`}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
      {!product?.is_valid && (
        <Box>
          <Typography
            sx={{
              color: "red",
              fontSize: ".9rem",
              textAlign: "start",
            }}
          >
            {product?.error_msg}
          </Typography>
        </Box>
      )}

      <div
        style={{
          width: "100%",
          border: "1px dashed #808080",
          margin: ".5rem",
        }}
      />
    </Grid>
  );
}

const cardStyles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
  },
  manuFactureDetails: {
    fontSize: "1.2rem",
    fontWeight: 500,
    textAlign: "start",
  },
  typoHeader: { fontSize: "1rem", fontWeight: "Bold", Padding: 2 },
  spanText: { fontSize: ".9rem", fontWeight: "500" },
};
