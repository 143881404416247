/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Button Field (Buuton Field)
 * @date 02/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and other Components
import { Button } from "@mui/material";
import React from "react";
import { appColors } from "../Assests/AppTheme/appThemeConst";

function PrimaryButton(props) {
  return (
    <Button
      size="small"
      sx={[styles.btnStyle, { backgroundColor: props.color }]}
      onClick={props.handleButtonClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.btnName}
    </Button>
  );
}

export default PrimaryButton;

const styles = {
  btnStyle: {
    color: appColors.white,
    textTransform: "none",
    fontSize: ".8rem",
    "&:hover": { backgroundColor: appColors.blueColor },
  },
};
