/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * @version 0.0.96
 * @Date 19-11-2024
 * @description Table template component. props for custom table includes
 *      tableData={userDetailsList}
        tableHead={["Full Name", "Roll Name", "Phone", "Organisation"]}
        tableDataKey={["fullname", "role_name", "phone", "org_name", {type: "button",
            enableKey: "is_facility",
            component: (props) =>{<Input />}}]}
        filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          { value: "Full Name", label: "fullname" },
        ]}
          handleFilterClose ---- Function returns filterdata as key value
        sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose ------ Function returns sortData as key value
        addButton={true}
        handleOnClickAddButton={() => setAddUserDialog(true)}

 */

import { Box, Paper, TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import appStyle from "../AppMeta/appStyles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CustomFilter from "../Buttons/customFilter";
import PrimaryButton from "../Buttons/primaryButton";
import { appColors } from "../Assests/AppTheme/appThemeConst";
import moment from "moment";
import CoustomPagiation from "../Custom/pagination";

function CustomTable({
  tableHead,
  tableDataKey,
  tableData,
  tableRowClickFunction,
  pagination,
  filter = false,
  filterSelectOption,
  handleFilterClose,
  sort = false,
  sortSelectOption,
  handleSortClose,
  addButton = false,
  handleOnClickAddButton,
  setPageNumber,
  setPageSize,
  addButtonName,
  pageNumber,
  pageSize,
  handelFilterDialog,
  coustomUi,
  filterButton,
}) {
  //declaration for PageNumbers
  // console.log("Tabledata", tableData);

  //functions for Pagination

  const handleChangeRowsPerPage = (event) => {
    // setPageSize(rowsPerPage);
    setPageSize(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  // console.log("tableData?.length");
  // console.log(tableData?.length);
  //function to handel pagesize change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
  };

  console.log(tableData);
  return (
    <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
          marginTop: ".3rem",
          alignItems: "flex-end",
          flexWrap: "wrap",
        }}
      >
        {filter && (
          <Box>
            <CustomFilter
              name="filter"
              type="filter"
              startIcon={<FilterAltIcon />}
              selectOption={filterSelectOption}
              handleFilterClose={handleFilterClose}
            />
          </Box>
        )}

        {sort && (
          <Box>
            <CustomFilter
              name="Sort By"
              type="sort"
              startIcon={<SwapVertIcon />}
              selectOption={sortSelectOption}
              handleFilterClose={handleSortClose}
            />
          </Box>
        )}
        {addButton && (
          <Box sx={{ marginBottom: ".5rem" }}>
            <PrimaryButton
              btnName={addButtonName}
              handleButtonClick={handleOnClickAddButton}
              color={appColors.blueColor}
            />
          </Box>
        )}
        <Box>{coustomUi && coustomUi()}</Box>

        {pagination === "100" ? (
          <Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tableData?.length}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        ) : pagination === false ? (
          <></>
        ) : (
          <CoustomPagiation
            setPageNumber={setPageNumber}
            handlePageSizeChange={handlePageSizeChange}
            pageNumber={pageNumber}
            handelFilterDialog={handelFilterDialog}
            pageSize={pageSize}
            data={tableData}
            filterButton={filterButton}
          />
        )}
      </Box>
      <TableContainer
        component={Paper}
        elevation={3}
        sx={appStyle.general.table.tableContainer}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead
            sx={{
              ...appStyle.general.table.tableHead,
            }}
          >
            <TableRow>
              {tableHead?.map((header, index) => {
                return (
                  <>
                    <TableCell
                      key={index}
                      sx={appStyle.general.table.headderRowCell}
                    >
                      {header}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody
          // sx={{ backgroundColor: colors.primary[600] }}
          >
            {tableData !== undefined &&
            tableData !== null &&
            tableData?.length > 0 ? (
              <>
                {(pageSize > 0 && pagination === "100"
                  ? tableData?.slice(
                      pageNumber * pageSize,
                      pageNumber * pageSize + pageSize
                    )
                  : tableData
                ).map((tableMapData, index) => {
                  const serialNumber = pageNumber * pageSize + index + 1;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        // backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "#95c1e785",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          ...appStyle.general.table.bodyRowCell,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          tableRowClickFunction(index, tableMapData);
                        }}
                      >
                        {serialNumber}
                      </TableCell>
                      {tableDataKey?.map((data, index) => {
                        if (typeof data === "string") {
                          let keys = data?.split(".");
                          //Take value from nested object
                          const value = keys?.reduce((obj, key) => {
                            if (
                              typeof obj?.[key] === "object" &&
                              obj?.[key] !== null &&
                              !Array.isArray(obj?.[key])
                            ) {
                              return obj?.[key];
                            }
                            return obj?.[key];
                          }, tableMapData);

                          return (
                            <TableCell
                              key={`${index}${index}`}
                              onClick={() => {
                                tableRowClickFunction(index, tableMapData);
                              }}
                              sx={{
                                ...appStyle.general.table.bodyRowCell,
                                cursor: "pointer",
                              }}
                            >
                              {value
                                ? keys.includes("created_at") ||
                                  keys.includes("updatedOn") ||
                                  keys.includes("createdDate")
                                  ? moment(value).format("DD/MMM/YY")
                                  : value
                                : "-"}
                            </TableCell>
                          );
                        } else if (
                          typeof data === "object" &&
                          typeof data.component === "function"
                        ) {
                          {
                            /* console.log("react element", data); */
                          }

                          // data.props.disabled = false;
                          return (
                            <TableCell
                              key={`${index}${index}`}
                              sx={appStyle.general.table.bodyRowCell}
                              // onClick={() => data.props.handleClick(index, data)}
                            >
                              {data.component({
                                disabled: !tableMapData[data.enableKey],
                                row: tableMapData,
                              })}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableCell colSpan={8}>
                <p
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  NO DATA FOUND
                </p>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CustomTable;
