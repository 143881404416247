/**
 * @copycenter Copycenter (c) 2024 Bookwater
 * @author Gowtham Prasath
 * @date 08-01-2025
 * @description Component to get the list of PDE Order details
 */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RegularOrdersTable from "../../Pages/AdminOrders/RegularOrdersTable";

export default function OrdersPageTabelView({
  tableHeader,
  orderList,
  handleOnUserSearchClick,
  handleSearchOrderId,
}) {
  return (
    <TableContainer sx={{ maxHeight: "600px", scrollbarWidth: "thin" }}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableRow sx={{ background: "#f5f5f5" }}>
            <TableCell />
            {tableHeader?.map((data, key) => {
              return (
                <TableCell
                  align="center"
                  sx={
                    data === "Order ID"
                      ? {
                          position: "sticky",
                          left: 0,
                          zIndex: 10,
                          backgroundColor: "white",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }
                      : { fontWeight: "bold", whiteSpace: "nowrap" }
                  }
                  key={key}
                >
                  {data}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderList?.map((row, index) => (
            <RegularOrdersTable
              index={index + 1}
              row={row}
              handleOnUserSearchClick={handleOnUserSearchClick}
              handleOpenOrderDetails={(data) => handleSearchOrderId(data)}
            />
          ))}
          {!orderList?.length ? (
            <TableCell align="center" colSpan={5} sx={{color:"red"}}> No data found!</TableCell>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
