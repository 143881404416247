import React, { useState } from "react";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte";

const TextEditor = ({ onChange, curentValue, tools }) => {
  const [value, setValue] = useState(
    curentValue
      ? RichTextEditor.createValueFromString(curentValue, "html")
      : RichTextEditor.createEmptyValue()
  );

  const handleChange = (newValue) => {
    setValue(newValue);
    if (onChange) {
      // Send the changes up to the parent component as an HTML string.
      onChange(newValue.toString("html"));
    }
  };

  // The toolbarConfig object allows you to specify custom buttons, reorder buttons, and to add custom CSS classes.
  // Supported inline styles: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Inline-Styles.md
  // Supported block types: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Custom-Block-Render.md#draft-default-block-render-map
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
      toolbarConfig={tools ? toolbarConfig : { display: [] }}
    />
  );
};

TextEditor.propTypes = {
  onChange: PropTypes.func,
};

export default TextEditor;
