import React from "react";
import { Alert, Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetPoTrackDetails } from "../Redux/Actions/adminPoTrackPostpaid";
import PoOrderTable from "./PoOrderTable";
import CreatePoDialog from "./CreatePoDialog";
import PlacePoOrder from "./PlacePoOrder";
import AssignSupplier from "./AssignSupplier";
import PoTrackFilterDialog from "./PoTrackFilterDialog";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InvoiceDialog from "./InvoiceDialog";
import SettlementsDialog from "./SettlementsDialog";
import { ADMIN_ROLES } from "../../Components/AppMeta/appMetaConfig";
import "../AdminPoTrackPostPaid/Style.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ConsolidatePOSettle from "./ConsolidatePOSettle";
import RegularBooking from "../UserDetails/RegularBooking";
import CustomConsole from "../../CustomConsole";
import BookRegularProducts from "../UserDetails/BookingChnage/BookRegularProducts";

function Postpaid() {
  const dispatch = useDispatch();
  const poDetails = useSelector((state) => state.poTrackDetails);

  const [poData, setPoData] = React.useState([]);
  const [openPoDialog, setOpenDialog] = React.useState(false);
  const [openOrderDialog, setOpenOrderDialog] = React.useState(false);
  const [updateSubscriptonText, setUpdateSubscriptonText] =
    React.useState(false);
  const [poAccepDialog, setPoAcceptDialog] = React.useState(false);
  const [filterDialog, setOpenFilterDialog] = React.useState(false);
  const [editSubscriptionDialog, setEditSubscriptionDialog] =
    React.useState(false);
  const [postPaidTrack, setPostPaidTrack] = React.useState("");
  const [userPhone, setUserPhone] = React.useState("");
  const [supplierId, setSupplier] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [poTrack, setPoTrack] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [sort, setSort] = React.useState("DESC");
  const [phone, setPhone] = React.useState("");
  const [filterApplyFlag, setFilterApply] = React.useState(false);
  const [invoiceDialog, setInvoiceDialog] = React.useState(false);
  const [invoiceData, setInvoiceData] = React.useState({});
  const [openSettle, setOpenSettle] = React.useState(false);
  const [poTrackSet, setPoTrackSet] = React.useState("");
  const [potrackErr, setPoTrackErr] = React.useState(false);
  const [lastDate, setLastDate] = React.useState("");
  const [poRemQty, setPoRemQty] = React.useState("");
  const [distributerName, setDistributerName] = React.useState("");
  const [alignment, setAlignment] = React.useState("old");
  const [stringId, setStringId] = React.useState("");
  const [poProducts, setPoproducts] = React.useState([]);
  const [createDeliveriesFlg, setCreateDeliveriesFlg] = React.useState(false);
  const [openBookingDialog, setOpenBookingDialog] = React.useState(false);
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [createDeliveryBtnFlag, setCreateDeliveryBtnFlag] =
    React.useState(false);
  const handleChange = (event, newAlignment) => {
    CustomConsole("------switch tab----");
    if (newAlignment) setAlignment(newAlignment);
    CustomConsole(newAlignment);
  };

  React.useEffect(() => {
    setPoData(poDetails?.poData ? poDetails?.poData : []);
    // CustomConsole(poDetails.poData);
    // CustomConsole(poDetails.poData?.order_details);
    CustomConsole(poDetails);
  }, [poDetails]);

  // React.useEffect(() => {
  //   let reqObj = {
  //     page_size: "",
  //     page_number: "",
  //     pp_track_id: "",
  //     order_status: "",
  //     sort: "",
  //     phone: "",
  //   };
  //   dispatch(GetPoTrackDetails(reqObj));
  // }, []);

  React.useEffect(() => {
    if (filterApplyFlag) {
      let reqObj = {
        page_size: pageSize,
        page_number: 0,
        pp_track_id: poTrack,
        order_status: orderType,
        sort: sort,
        phone: phone,
      };

      dispatch(GetPoTrackDetails(reqObj));
      setOpenFilterDialog(false);
      setFilterApply(false);
    }
  }, [pageSize, poTrack, orderType, sort, phone, filterApplyFlag]);

  React.useEffect(() => {
    let reqObj = {
      page_size: pageSize,
      page_number: pageNumber,
      pp_track_id: poTrack,
      order_status: orderType,
      sort: sort,
      phone: phone,
    };

    dispatch(GetPoTrackDetails(reqObj));
  }, [pageNumber]);

  return (
    <>
      {parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SUPERADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CS ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_CSLEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCE ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_FINANCEHEAD ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_ADMIN ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALES ||
      parseInt(localStorage.getItem("roleId")) === ADMIN_ROLES.BW_SALESHEAD ? (
        <Grid sx={{ marginTop: "50px" }} container spacing={2}>
          <Box>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="old">OLD</ToggleButton>
              <ToggleButton value="new">NEW</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {alignment === "old" ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setOpenFilterDialog(true)}
                    startIcon={<FilterAltIcon />}
                  >
                    Filter
                  </Button>
                  {parseInt(localStorage.getItem("roleId")) ===
                    ADMIN_ROLES.BW_CS ||
                  parseInt(localStorage.getItem("roleId")) ===
                    ADMIN_ROLES.BW_CSHEAD ||
                  parseInt(localStorage.getItem("roleId")) ===
                    ADMIN_ROLES.BW_CSLEAD ||
                  parseInt(localStorage.getItem("roleId")) ===
                    ADMIN_ROLES.BW_FINANCEHEAD ||
                  parseInt(localStorage.getItem("roleId")) ===
                    ADMIN_ROLES.BW_ADMIN ||
                  parseInt(localStorage.getItem("roleId")) ===
                    ADMIN_ROLES.BW_SUPERADMIN ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setOpenDialog(true)}
                    >
                      Create Postpaid Order
                    </Button>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <PoOrderTable
                  setCreateDeliveriesFlg={setCreateDeliveriesFlg}
                  poData={poData}
                  setOpenOrderDialog={setOpenBookingDialog}
                  assignDialog={setPoAcceptDialog}
                  setPostPaidTrack={setPostPaidTrack}
                  setUserPhone={setUserPhone}
                  setSupplier={setSupplier}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  setFilterApply={setFilterApply}
                  setInvoiceDialog={setInvoiceDialog}
                  setInvoiceData={setInvoiceData}
                  setOpenSettle={setOpenSettle}
                  setPoTrackSet={setPoTrackSet}
                  setLastDate={setLastDate}
                  pageSize={pageSize}
                  setPoRemQty={setPoRemQty}
                  setDistributerName={setDistributerName}
                  setStringId={setStringId}
                  setPoproducts={setPoproducts}
                  setCreateDeliveryBtnFlag={setCreateDeliveryBtnFlag}
                  createDeliveryBtnFlag={createDeliveryBtnFlag}
                />
              </Grid>
            </>
          ) : alignment === "new" ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ConsolidatePOSettle />
            </Grid>
          ) : (
            ""
          )}

          <CreatePoDialog open={openPoDialog} setOpen={setOpenDialog} />

          {/* //New order Dialog */}
          {openBookingDialog && (
            <BookRegularProducts
              setEditSubscriptionDialog={setEditSubscriptionDialog}
              setUpdateSubscriptonText={setUpdateSubscriptonText}
              setCreateDeliveriesFlg={setCreateDeliveriesFlg}
              createDeliveriesFlg={createDeliveriesFlg}
              open={openBookingDialog}
              close={setOpenBookingDialog}
              userId={stringId}
              poProducts={poProducts}
              isPostpaid={true}
              supplierId={supplierId}
              setSupplier={setSupplier}
              poTrack={postPaidTrack}
              distributerName={distributerName}
              setDistributerName={setDistributerName}
              lastDate={lastDate}
              setSelectedDays={setSelectedDays}
              setCreateDeliveryBtnFlag={setCreateDeliveryBtnFlag}
              createDeliveryBtnFlag={createDeliveryBtnFlag}
            />
          )}
          {/* <RegularBooking
            open={openOrderDialog}
            setOpen={setOpenOrderDialog}
            userId={stringId}
            poProducts={poProducts}
            isPostpaid={true}
            supplierId={supplierId}
            setSupplier={setSupplier}
            poTrack={postPaidTrack}
            distributerName={distributerName}
            setDistributerName={setDistributerName}
            lastDate={lastDate}
          /> */}

          {/* <PlacePoOrder
            open={openOrderDialog}
            setOpen={setOpenOrderDialog}
            userPhone={userPhone}
            supplierId={supplierId}
            postPaidTrack={postPaidTrack}
            lastDate={lastDate}
            poRemQty={poRemQty}
            setSupplier={setSupplier}
            distributerName={distributerName}
          /> */}
          <AssignSupplier
            open={poAccepDialog}
            setOpen={setPoAcceptDialog}
            postPaidTrack={postPaidTrack}
          />
          <PoTrackFilterDialog
            setOpen={setOpenFilterDialog}
            open={filterDialog}
            setPageSize={setPageSize}
            pageSize={pageSize}
            poTrack={poTrack}
            setPoTrack={setPoTrack}
            orderType={orderType}
            setOrderType={setOrderType}
            setSort={setSort}
            sort={sort}
            phone={phone}
            setPhone={setPhone}
            filterApplyFlag={filterApplyFlag}
            setFilterApply={setFilterApply}
            potrackErr={potrackErr}
            setPoTrackErr={setPoTrackErr}
            pageNumber={pageNumber}
          />
          <InvoiceDialog
            open={invoiceDialog}
            setOpen={setInvoiceDialog}
            invoiceData={invoiceData}
            pageNumber={pageNumber}
            phone={phone}
            pageSize={pageSize}
            poTrack={poTrack}
            orderType={orderType}
            sort={sort}
            poTrackSet={poTrackSet}
          />
          <SettlementsDialog
            open={openSettle}
            setOpen={setOpenSettle}
            poTrackSet={poTrackSet}
            userPhone={userPhone}
            pageNumber={pageNumber}
            phone={phone}
            pageSize={pageSize}
            poTrack={poTrack}
            orderType={orderType}
            sort={sort}
          />
        </Grid>
      ) : (
        <Box sx={{ marginTop: "50px" }}>
          <Alert severity="error">Access Denied</Alert>
        </Box>
      )}
    </>
  );
}

export default Postpaid;
